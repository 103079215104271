import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Message, Tab } from 'semantic-ui-react';

import { AuthContext } from '../../contexts';
import { API, Logger } from '../../services';

class Profile extends Component {

  state = {
    input: {
      locale: 'en-US',
    },
    loading: true,
    message: {
      field: {},
    },
  };
  userFields = ['birthday', 'email', 'first_name', 'last_name', 'locale', 'phone'];

  constructor(props) {
    super(props);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.handleDismissSuccessMessage = this.messageDismissedListener('success').bind(this);
    this.handleInputChange = this.inputChangeListener.bind(this);
    this.handleSubmit = this.submitListener.bind(this);
  }

  /**
   * @return {void}
   */
  componentDidMount() {
    API
        .get(`/api/v1/users/${this.context.user.id}`)
        .then((response) => {
          const { birthday, email, first_name, last_name, phone } = response.data;
          this.setState({
            input: {
              ...this.state.input,
              birthday,
              email,
              first_name,
              last_name,
              phone,
            },
          });
        })
        .catch((reason) => {
          Logger.error(reason);
          this.setState({
            message: {
              ...this.state.message,
              error: 'retrieve',
            },
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {InputOnChangeData}  data
   */
  inputChangeListener(event, { name, value }) {
    const { input } = this.state;
    input[name] = value;
    this.setState({ input });
  }

  /**
   * @param  {String} name
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({ message });
    };
  }

  /**
   * @param  {React.FormEvent} event
   */
  submitListener(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    API
      .put(`/api/v1/users/${this.context.user.id}`, this.state.input)
      .then((response) => {
        this.context.updateUser({
          ...this.context.user,
          ...this.state.input,
        });
        this.setState({
          message: {
            ...this.state.message,
            success: 'profile',
          },
        });
      })
      .catch((reason) => {
        this.setState({
          message: {
            ...this.state.message,
            error: 'default',
            field: reason.response.data.errors || {},
          },
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    const { t } = this.props;
    return (
      <Tab.Pane>
        <Form error={!!this.state.message.error} loading={this.state.loading} onSubmit={this.handleSubmit} success={!!this.state.message.success}>
          <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
          <Message
            content={t(`message.success.${this.state.message.success}.content`)}
            success
            icon='check'
            header={t(`message.success.${this.state.message.success}.title`)}
            onDismiss={this.handleDismissSuccessMessage}
          />
          <Form.Group widths="2">
            <Form.Input
              type="text"
              autoComplete="given-name"
              error={
                this.state.message.field.first_name
                && {
                  content: this.state.message.field.first_name.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('auth:input.label.firstName')}
              name="first_name"
              onChange={this.handleInputChange}
              placeholder={t('auth:input.placeholder.firstName')}
              required
              value={this.state.input.first_name}
            />
            <Form.Input
              type="text"
              autoComplete="family-name"
              error={
                this.state.message.field.last_name
                && {
                  content: this.state.message.field.last_name.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('auth:input.label.lastName')}
              maxLength={30}
              name="last_name"
              onChange={this.handleInputChange}
              placeholder={t('auth:input.placeholder.lastName')}
              value={this.state.input.last_name}
            />
          </Form.Group>
          <Form.Input
            type="email"
            autoComplete="username"
            error={
              this.state.message.field.email
              && {
                content: this.state.message.field.email.join("\n"),
                pointing: 'below',
              }
            }
            label={t('auth:input.label.email')}
            name="email"
            onChange={this.handleInputChange}
            placeholder={t('auth:input.placeholder.email')}
            readOnly
            required
            value={this.state.input.email}
          />
          <Form.Group widths="2">
            <Form.Input
              type="date"
              autoComplete="bday"
              error={
                this.state.message.field.birthday
                && {
                  content: this.state.message.field.birthday.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('input.label.birthday')}
              name="birthday"
              onChange={this.handleInputChange}
              placeholder={t('input.placeholder.birthday')}
              value={this.state.input.birthday}
            />
            <Form.Input
              type="tel"
              autoComplete="tel"
              error={
                this.state.message.field.phone
                && {
                  content: this.state.message.field.phone.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('input.label.phone')}
              name="phone"
              onChange={this.handleInputChange}
              placeholder={t('input.placeholder.phone')}
              value={this.state.input.phone}
            />
          </Form.Group>
          <Form.Button color='blue' fluid type='submit'>
            {t('button.save')}
          </Form.Button>
        </Form>
      </Tab.Pane>
    );
  }

}

/**
 * @static {React.Context<Auth>}
 */
Profile.contextType = AuthContext;

export default withTranslation('settings')(Profile);
