import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Dimmer,
  Form,
  Header,
  Icon,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';

import { API, FormErrorHandler } from '../../services';

class Edit extends Component {
  /**
   * @property {Objact}
   */
  state = {
    initialInput: {},
    input: {},
    loading: false,
    message: {
      field: {},
    },
    roles: [],
  };

  /**
   * @param  {Readonly<Object>}  props
   * @constructs Create
   */
  constructor(props) {
    super(props);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.handleDismissSuccessMessage = this.messageDismissedListener('success').bind(this);
    this.handleInputChange = this.inputChangeListener.bind(this);
    this.handleMount = this.mountListener.bind(this);
    this.handleReset = this.resetListener.bind(this);
    this.handleSubmit = this.submitListener.bind(this);
    this.handleUnmount = this.unmountListener.bind(this);
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {InputOnChangeData}  data
   * @param  {String}  data.name
   * @param  {number}  index
   * @param  {any}  data.value
   */
  inputChangeListener(event, { name, value }, index) {
    const { input } = this.state;
    if (index !== undefined) {
      input[name][index] = value;
    } else {
      input[name] = value;
    }
    this.setState({ input });
  }

  /**
   * @param  {String} name
   *
   * @return {Function(event: React.MouseEvent): void}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({
        message: {
          ...this.state.message,
          [name]: null,
        },
      });
    };
  }

  /**
   * @param  {null}  nothing
   * @param  {ModalProps}  data
   * 
   * @return {void}
   */
  mountListener(nothing, data) {
    if (!this.state.loading) {
      this.setState({
        loading: true,
      });
      API
        .get('/api/v1/roles')
        .then((response) => {
          this.setState({
            roles: response.data.map(
              (role, index) => ({
                key: index,
                text: role.name,
                value: role.id,
              })
            ),
          });
          return API.get(`/api/v1/users/${this.props.userId}`);
        })
        .then((response) => {
          this.setState({
            initialInput: Object.assign({id: this.props.userId}, response.data),
            input: Object.assign({id: this.props.userId}, response.data),
          });
        })
        .catch((reason) => {
          this.setState({
            message: {
              ...this.state.message,
              error: 'retrieve',
            },
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }
  }

  /**
   * @param  {Object}  errors
   * 
   * @return {void}
   */
  onFieldError(errors) {
    this.setState({
      message: {
        error: 'fields',
        success: null,
        field: errors,
      },
    });
  }

  /**
   * @return {void}
   */
  onServerError() {
    this.setState({
      message: {
        error: 'server',
        success: null,
        field: {},
      },
    });
  }

  /**
   * @return {void}
   */
  onUnknownError() {
    this.setState({
      message: {
        error: 'unexpected',
        success: null,
        field: {},
      },
    });
  }

  /**
   * @param  {React.FormEvent}  event
   * 
   * @return {void}
   */
  resetListener(event) {
    event.preventDefault();
    this.setState({
      input: {
        permissions: [null],
      },
    });
  }

  /**
   * @param  {React.FormEvent}  event
   * 
   * @return {void}
   */
  submitListener(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    API
      .put(`/api/v1/users/${this.props.userId}`, this.state.input)
      .then((response) => {
        this.setState({
          initialInput: Object.assign({id: this.props.userId}, response.data),
          input: Object.assign({id: this.props.userId}, response.data),
          message: {
            error: null,
            field: {},
            success: 'edit',
          },
        });
      })
      .catch((reason) => {
        FormErrorHandler(
          reason,
          {
            onFieldError: this.onFieldError.bind(this),
            onServerError: this.onServerError.bind(this),
            onUnknownError: this.onUnknownError.bind(this),
          }
        );
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * @param  {null}  nothing
   * @param  {SemanticUI.ModalProps}  data
   * 
   * @return {void}
   */
  unmountListener(nothing, data) {
    this.setState({
      initialInput: {},
      input: {},
      message: {
        field: {},
      },
      roles: [],
    });
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    const { onClose, open, t } = this.props;
    return (
      <Modal
        as={Form}
        closeIcon={!this.state.loading}
        closeOnDimmerClick={!this.state.loading}
        closeOnDocumentClick={!this.state.loading}
        error={Boolean(this.state.message.error)}
        onClose={onClose}
        onMount={this.handleMount}
        onReset={this.handleReset}
        onSubmit={this.handleSubmit}
        onUnmount={this.handleUnmount}
        open={open}
        success={Boolean(this.state.message.success)}
      >
        <Dimmer active={this.state.loading} inverted>
          <Loader />
        </Dimmer>
        <Header icon="pencil" content={t('title.edit', { email: this.state.initialInput.email })} />
        <Modal.Content scrolling>
          <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
          <Message
            content={t(`message.success.${this.state.message.success}.content`)}
            success
            icon='check'
            header={t(`message.success.${this.state.message.success}.title`)}
            onDismiss={this.handleDismissSuccessMessage}
          />
          <Form.Group widths="2">
            <Form.Input
              type="text"
              error={
                this.state.message.field.first_name
                && {
                  content: this.state.message.field.first_name.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('auth:input.label.firstName')}
              name="first_name"
              onChange={this.handleInputChange}
              placeholder={t('auth:input.placeholder.firstName')}
              required
              value={this.state.input.first_name}
            />
            <Form.Input
              type="text"
              error={
                this.state.message.field.last_name
                && {
                  content: this.state.message.field.last_name.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('auth:input.label.lastName')}
              maxLength={30}
              name="last_name"
              onChange={this.handleInputChange}
              placeholder={t('auth:input.placeholder.lastName')}
              value={this.state.input.last_name}
            />
          </Form.Group>
          <Form.Group widths="2">
            <Form.Input
              type="email"
              error={
                this.state.message.field.email
                && {
                  content: this.state.message.field.email.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('auth:input.label.email')}
              name="email"
              onChange={this.handleInputChange}
              placeholder={t('auth:input.placeholder.email')}
              readOnly
              required
              value={this.state.input.email}
            />
            <Form.Dropdown
              error={
                this.state.message.field.role_id
                && {
                  content: this.state.message.field.role_id.join("\n"),
                  pointing: 'below',
                }
              }
              fluid
              label={t('input.label.role')}
              name="role_id"
              options={this.state.roles}
              onChange={this.handleInputChange}
              placeholder={t('input.placeholder.role')}
              required
              search
              selection
              value={this.state.input.role_id}
            />
          </Form.Group>
          <Form.Group widths="2">
            <Form.Input
              type="date"
              error={
                this.state.message.field.birthday
                && {
                  content: this.state.message.field.birthday.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('input.label.birthday')}
              name="birthday"
              onChange={this.handleInputChange}
              placeholder={t('input.placeholder.birthday')}
              value={this.state.input.birthday}
            />
            <Form.Input
              type="tel"
              error={
                this.state.message.field.phone
                && {
                  content: this.state.message.field.phone.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('input.label.phone')}
              name="phone"
              onChange={this.handleInputChange}
              placeholder={t('input.placeholder.phone')}
              value={this.state.input.phone}
            />
          </Form.Group>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={onClose} type="reset">
            <Icon name='remove' /> {t('button.cancel')}
          </Button>
          <Button color='blue' type="reset">
            <Icon name='refresh' /> {t('button.reset')}
          </Button>
          <Button color='green' type="submit">
            <Icon name='checkmark' /> {t('button.done')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

/**
 * @static {Object}
 */
Edit.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  userId: PropTypes.oneOfType(
    [
      PropTypes.bool,
      PropTypes.string,
    ]
  ).isRequired,
};

export default withTranslation('users')(Edit);
