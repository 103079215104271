import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Auth from './Auth';
import Clients from './Clients';
import Dashboard from './Dashboard';
import NotFound from './NotFound';
import Projects from './Projects';
import Roles from './Roles';
import Settings from './Settings';
import Users from './Users';
import { Page, ProtectedRoute } from '../components';

class Views extends React.Component {
  render() {
    return (
      <Router>
        <Page>
          <Switch>
            <ProtectedRoute component={Dashboard} exact path='/' />
            <ProtectedRoute component={Clients} path='/clients' />
            <ProtectedRoute component={Projects} path='/projects' />
            <ProtectedRoute component={Clients} path='/companies' />
            <ProtectedRoute component={Roles} path='/roles' />
            <ProtectedRoute component={Users} path='/users' />
            <ProtectedRoute component={Settings} path='/settings' />
            <Route component={Auth} path='/auth' />
            <Route component={NotFound} path='*' />
          </Switch>
        </Page>
      </Router>
    );
  }
}

export default Views;