import React, { Component, Fragment } from 'react';
import { Route } from 'react-router-dom';

import Auth from './Auth';
import Sidebar from '../Sidebar';
import Topbar from '../Topbar';
import { AuthContext } from '../../contexts';
import './style.css';

class Page extends Component {

  /**
   * @return {React.ReactNode}
   */
  render() {
    return this.context.authenticated
      ? (
        <Fragment>
          <Route component={Sidebar} path="*" strict />
          <Topbar />
          <Page.Container>
            {this.props.children}
          </Page.Container>
        </Fragment>
      )
      : (
        <Page.Container.Auth>
          {this.props.children}
        </Page.Container.Auth>
      );
  }
}

class Container extends Component {
  /**
   * @return {React.ReactNode}
   */
  render() {
    return (
      <div className="ax-full-height ax-padding ax-page">
        {this.props.children}
      </div>
    );
  }
}

/**
 * @static  {React.Component}
 */
Container.Auth = Auth;

/**
 * @static  {React.Component}
 */
Page.Container = Container;

/**
 * @static {React.Context<Auth>}
 */
Page.contextType = AuthContext;

export default Page;
