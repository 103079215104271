import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form, Message } from 'semantic-ui-react';

import { Content } from '../../components';
import { AuthContext } from '../../contexts';
import { API } from '../../services';

class SendLink extends Component {

  state = {
    input: {},
    loading: false,
    message: {
      field: {},
      info: 'sendLink',
    },
  };

  constructor(props) {
    super(props);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.handleDismissSuccessMessage = this.messageDismissedListener('success').bind(this);
    this.handleInputChange = this.inputChangeListener.bind(this);
    this.handleSubmit = this.submitListener.bind(this);
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {InputOnChangeData}  data
   */
  inputChangeListener(event, { name, value }) {
    const { input } = this.state;
    input[name] = value;
    this.setState({ input });
  }

  /**
   * @param  {String} name
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({ message });
    };
  }

  /**
   * @param  {React.FormEvent} event
   */
  submitListener(event) {
    event.preventDefault();
    this.setState({ loading: true });
    API
      .post(
        '/api/v1/auth/password/link',
        this.state.input,
      )
      .then((response) => {
        this.setState({
          input: {},
          message: {
            ...this.state,
            success: 'sendLink',
          },
        });
      })
      .catch((reason) => {
        const { message } = this.state;
        message.error = 'default';
        message.field = reason.response.data.errors || {};
        this.setState({ message });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  /**
   * @return {React.Component}
   */
  render() {
    const { t } = this.props;
    return (
      <Content.Auth message={this.state.message.info}>
        {
          this.state.message.success
          && <Message
            content={t(`message.success.${this.state.message.success}.content`)}
            icon='exclamation'
            header={t(`message.success.${this.state.message.success}.title`)}
            onDismiss={this.handleDismissSuccessMessage}
            success
          />
        }
        <Form error={!!this.state.message.error} onSubmit={this.handleSubmit} loading={this.state.loading}>
          <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
          <Form.Input
            type="email"
            autoComplete="email"
            error={
              this.state.message.field.email
              && {
                content: this.state.message.field.email.join("\n"),
                pointing: 'below'
              }
            }
            label={t('input.label.email')}
            maxLength={128}
            minLength={3}
            name="email"
            onChange={this.handleInputChange}
            placeholder={t('input.placeholder.email')}
            required
          />
          <Form.Button color='blue' fluid type='submit'>{t('button.sendLink')}</Form.Button>
        </Form>
        <p>
          {t('message.login')} <Link to="/auth/login">{t('link.login')}</Link>
          <br />
          {t('message.register')} <a href="https://gluu.digitalrevisor.eu/portal/">{t('link.register')}</a>
        </p>
      </Content.Auth>
    );
  }
}

/**
 * @static {React.Context<Auth>}
 */
SendLink.contextType = AuthContext;

export default withTranslation('auth')(SendLink);
