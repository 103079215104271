import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react';

import logo from '../../resources/assets/DigitalRevisorLogo.png';

class Content extends Component {

    constructor(props) {
      super(props);
      this.handleDismissMessage = this.messageDismissedListener.bind(this);
      this.state = {
        message: props.message,
      };
    }
  
    /**
     * @param {Readonly} prevProps
     * @param {Readonly} prevState
     *
     * @return {void}
     */
    componentDidUpdate(prevProps, prevState) {
      const { message: prevMessage } = prevProps;
      const { message: currMessage } = this.props;
      if (prevMessage !== currMessage) {
        this.setState({ message: currMessage });
      }
    }
  
    /**
     * @param  {React.MouseEvent} event
     *
     * @return {function(React.MouseEvent)}
     */
    messageDismissedListener(event) {
      this.setState({ message: null });
    }
  
    /**
     * @return {React.ReactNode}
     */
    render() {
      const { children, t } = this.props;
      return (
        <div className="ax-auth-content">
          <p className="ax-auth-centered">
            <img alt={t('image.alt.logo')} width="160" src={logo} />
          </p>
          {
            this.state.message
            && <Message
              content={t(`message.info.${this.state.message}.content`)}
              icon='info'
              info
              header={t(`message.info.${this.state.message}.title`)}
              onDismiss={this.handleDismissMessage}
            />
          }
          {children}
        </div>
      );
    }
  }

  /**
   * @static {React.PropTypes}
   */
  Content.propTypes = {
    message: PropTypes.string,
  }

  export default withTranslation('auth')(Content);
