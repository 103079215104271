import Storage from './Storage';

class Auth extends EventTarget {

  static CREDENTIAL_STORAGE_KEY = 'AXAuth';
  static PROFILE_STORAGE_KEY = 'AXProfile';
  static USER_STORAGE_KEY = 'AXUser';

  /**
   * @property  {Storage}
   */
  storage = new Storage();

  /**
   * @property  {number}
   */
  updatedAt = Date.now();

  /**
   * @return {bool}
   */
  get authenticated() {
    return Boolean(this.token);
  }

  /**
   * @property {Object}
   */
  get credential() {
    return this.storage.get(Auth.CREDENTIAL_STORAGE_KEY, {
      accessToken: '',
      access_token: '',
    });
  }

  /**
   * @property {Object}
   */
  get profile() {
    return this.storage.get(Auth.PROFILE_STORAGE_KEY, {});
  }

  /**
   * @property {String}
   */
  get token() {
    return this.credential.accessToken || this.credential.access_token;
  }

  /**
   *
   * @property {Object}
   */
  get user() {
    return this.storage.get(Auth.USER_STORAGE_KEY, {});
  }

  /**
   *
   * @param {Object} credential
   */
  authenticate = ({ credential, user }) => {
    this.storage.set(Auth.CREDENTIAL_STORAGE_KEY, credential);
    this.storage.set(Auth.USER_STORAGE_KEY, user);
    this._update();
  };

  /**
   * @return {void}
   */
  clear = () => {
    this.storage.remove(Auth.USER_STORAGE_KEY);
    this.storage.remove(Auth.PROFILE_STORAGE_KEY);
    this.storage.remove(Auth.CREDENTIAL_STORAGE_KEY);
    this._update();
  };

  /**
   *
   * @param {Object} user
   */
  signUp = (user) => {
    this.storage.set(Auth.PROFILE_STORAGE_KEY, user);
    this._update();
  };

  /**
   * @return {void}
   */
  _update() {
    this.updatedAt = Date.now();
    this.dispatchEvent(
      new Auth.Event(this),
    );
  }

  /**
   * @return {void}
   */
  updateUser = (user) => {
    this.storage.set(Auth.USER_STORAGE_KEY, user);
    this._update();
  };

}

/**
 * @property {typeof Event}
 */
Auth.Event = class extends Event {

  /**
   *
   * @param {Object} data
   */
  data = {};

  /**
   * @param  {Auth}  data
   */
  constructor(data) {
    super('auditxess.auth.change');
    const { authenticated, credential, profile, user } = data;
    this.data = {
      ...data,
      authenticated,
      credential,
      profile,
      user,
    };
  }

};

export default Auth;
