import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { AuthContext } from '../contexts';

class ProtectedRoute extends Component {

  render() {
    return this.context.authenticated ? <Route {...this.props} /> : <Redirect to="/auth/login" />;
  }
}

/**
 * @property {React.Context<Auth>}
 */
ProtectedRoute.contextType = AuthContext;

export default ProtectedRoute;
