import React from 'react';import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Header, Message, Table } from 'semantic-ui-react';

import Create from './Create';
import { Content } from '../../components';
import { API } from '../../services';

class Projects extends React.Component {
  /**
   * @property {Object}
   */
  state = {
    creating: false,
    loading: true,
    message: {},
    projects: [],
  };

  /**
   * @param {Readonly} props
   * 
   * @constructs List
   */
  constructor(props) {
    super(props);
    this.handleCreateClose = this.createCloseListener.bind(this);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.pageActions = [
      {
        handler: this.createOpenListener.bind(this),
        label: props.t('button.create'),
      },
    ];
  }

  /**
   * @return {void}
   */
  componentDidMount() {
    API
      .get('/api/v1/projects')
      .then((response) => {
        this.setState({
          clients: response.data,
        });
      })
      .catch((reason) => {
        this.setState({
          message: {
            ...this.state.message,
            error: 'retrieve',
          },
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  /**
   * @param  {React.MouseEvent} event 
   * 
   * @return {void}
   */
  createCloseListener(event) {
    this.setState({ creating: false });
  }

  /**
   * @param  {React.MouseEvent} event 
   * 
   * @return {void}
   */
  createOpenListener(event) {
    this.setState({ creating: true });
  }

  /**
   * @param  {String}  id
   * @param  {React.MouseEvent} MouseEvent
   * 
   * @return {void}
   */
  handleDelete(id, event) {

  }

  /**
   * @param  {String} name
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({ message });
    };
  }

  render() {
    const { match: { url }, t } = this.props;
    return (
      <Content actions={this.pageActions} title={t('title.index')}>
        {
          this.state.message.error
          && <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
        }
        <Table fixed striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('table.header.client')}</Table.HeaderCell>
              <Table.HeaderCell>{t('table.header.year')}</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              this.state.projects.length === 0
              && (
                <Table.Row cells={1} warning>
                  <Table.Cell colspan="3" textAlign='center'>
                    <Header size="tiny">{t(`message.warn.empty.title`)}</Header>
                    <p>{t(`message.warn.empty.content`)}</p>
                  </Table.Cell>
                </Table.Row>
              )
            }
            {
              this.state.projects.map(
                (project, index) => (
                  <Table.Row key={`project${index}`}>
                    <Table.Cell>{project.client}</Table.Cell>
                    <Table.Cell>{project.year}</Table.Cell>
                    <Table.Cell>
                      <Button as={Link} floated="right" to={`${url}/${project.id}`}>{t('button.view')}</Button>
                      <Button floated="right" onClick={this.handleDelete.bind(this, project.id)} to={`${url}/${project.id}`}>{t('button.view')}</Button>
                    </Table.Cell>
                  </Table.Row>
                )
              )
            }
          </Table.Body>
        </Table>
        <Create onClose={this.handleCreateClose} open={this.state.creating} />
      </Content>
    );
  }
}

export default withTranslation('projects')(Projects);