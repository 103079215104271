import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { AuthContext } from '../../contexts';
import Login from './Login';
import Register from './Register';
import ResetPassword from './ResetPassword';
import SendLink from './SendLink';
import SetPassword from './SetPassword';

class Auth extends React.Component {

  render() {
    const { match: { url } } = this.props;
    return (
      <Switch>
        <Route component={Login} exact path={`${url}/login`} />
        <Route component={Register} exact path={`${url}/register`} />
        <Route component={SendLink} exact path={`${url}/password/link`} />
        <Route component={ResetPassword} exact path={`${url}/password/reset/:token`} />
        <Route component={SetPassword} exact path={`${url}/password/set/:token`} />
      </Switch>
    );
  }
}

/**
 * @static {React.Context<Auth>}
 */
Auth.contextType = AuthContext;

export default Auth;
