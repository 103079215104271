export default {
  button: {
    cancel: 'Cancel',
    create: 'Create User',
    done: 'Done',
    edit: 'Edit',
    reset: 'Reset',
    save: 'Save',
  },
  input: {
    label: {
      birthday: 'Birthday',
      email: 'E-mail Address',
      firstName: 'First Name',
      lastName: 'Last Name',
      phone: 'Phone Number',
      role: 'Role',
    },
    placeholder: {
      birthday: 'The user\'s birthday',
      email: 'Their email address',
      firstName: 'Their first name',
      lastName: 'Their last name',
      phone: 'Phone Number',
      role: 'Select a role',
    },
  },
  message:{
    error: {
      fields: {
        content: 'Check your fields then try again.',
        title: 'Oops!',
      },
      retrieve: {
        content: 'Unable to get the required information to display here.',
        title: 'Error retrieving information',
      },
      server: {
        content: 'A problem occurred while communicating with the server. Please try again later.',
        title: 'Something went wrong!',
      },
      unexpected: {
        content: 'An unexpected issue occurred. Please try again later.',
        title: 'Something went wrong!',
      },
    },
    info: {},
    success: {
      create: {
        content: 'The new user has been added successfully.',
        title: 'User added!',
      },
      edit: {
        content: 'The user profile has been updated successfully.',
        title: 'Update successful!',
      },
    },
  },
  title: {
    create: 'Create User',
    edit: 'Edit {{email}}\'s Profile',
    index: 'Users',
  },
}