import React from 'react';
import { Link } from 'react-router-dom';
import { Icon, Menu } from 'semantic-ui-react';

import IonIcon from '../IonIcon';
import logo from '../../resources/assets/DigitalRevisorLogo.png';
import './style.css';

const sidebarConfig = [
  {
    icon: 'speedometer',
    label: 'Dashboard',
    url: '/',
  },
  {
    icon: 'chatbubbles',
    label: 'Chat',
    url: '/',
  },
  {
    icon: 'people',
    label: 'Clients',
    url: '/clients',
  },
  {
    icon: 'journal',
    label: 'Projects',
    url: '/projects',
  },
  {
    icon: 'chatbox',
    label: 'Communication',
    url: '/',
  },
  {
    icon: 'briefcase',
    label: 'Accountants',
    url: '/',
  },
  {
    icon: 'business',
    label: 'Companies',
    url: '/',
  },
  {
    icon: 'person',
    label: 'Users',
    url: '/users',
  },
  {
    icon: 'shield',
    label: 'Roles & Permissions',
    url: '/roles',
  },
  {
    icon: 'settings',
    label: 'Settings',
    url: '/settings',
  },
];

class Sidebar extends React.Component {

  constructor(props) {
    super(props);
    this.config = sidebarConfig.filter((item) => item instanceof Object);
  }

  render() {
    const { match: { url } } = this.props;
    return (
      <div className="ax-sidebar">
        <Menu
          borderless={false}
          fluid
          vertical
        >
          <Menu.Item key="sidebarItem-1">
            <img alt="Digital Revisor" src={logo} />
          </Menu.Item>
          {
            this.config.map(
              (item, index) => (
                <Menu.Item active={url === item.url} as={Link} key={`sidebarItem${index}`} to={item.url}>
                  <Icon as={IonIcon} iName={`${item.icon}-outline`}></Icon>
                  <span>{item.label}</span>
                </Menu.Item>
              )
            )
          }
        </Menu>
      </div>
    );
  }
}

export default Sidebar;
