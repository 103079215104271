import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ClientList from './List';
import Client from './Client';

class Clients extends React.Component {
  
  render() {
    const { match: { url } } = this.props;
    return (
      <Switch>
        <Route component={ClientList} exact path={url} />
        <Route component={Client} path={`${url}/:clientId`} />
      </Switch>
    );
  }
}

export default Clients;