/**
 * Handle form submission errors by calling the appropriate callback
 * 
 * @param  {any}  error
 * @param  {Object}  callbacks
 * @param  {Function}  [callbacks.onClientError = Function.prototype]
 * @param  {Function(Array)}  [callbacks.onFieldError = Function.prototype]
 * @param  {Function}  [callbacks.onServerError = Function.prototype]
 * @param  {Function}  [callbacks.onUnauthorizedError = Function.prototype]
 * @param  {Function}  [callbacks.onUnknownError = Function.prototype]
 * 
 * @return {void}
 */
function FormErrorHandler(error, {
  onClientError = Function.prototype,
  onFieldError = Function.prototype,
  onServerError = Function.prototype,
  onUnauthorizedError = Function.prototype,
  onUnknownError = Function.prototype,
}) {
  if (!(error instanceof Object)) {
    error = {};
  }
  if (!error.hasOwnProperty('response')) {
    error.response = { status: { code: 0, }, };
  }
  switch (error.response.status) {

    case 400:
      onClientError();
      break;
      
    case 403:
      onUnauthorizedError();
      break;

    case 422:
      onFieldError(error.response.data.errors);
      break;

    case 500:
    case 502:
    case 503:
      onServerError();
      break;

    default:
      onUnknownError();
  }
};

export default FormErrorHandler;
