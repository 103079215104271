import axios from 'axios';
import i18next from 'i18next';

import Auth from './Auth';

const auth = new Auth();
const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URI,
});

API.interceptors.request.use((config) => {
  if (auth.token !== '') {
    config.headers.Authorization = `Bearer ${auth.token}`
  }
  config.headers.accept = 'application/json';
  config.headers['X-Auditxess-Lang'] = i18next.language;
  return config
}, (err) => Promise.reject(err));

API.interceptors.response.use(undefined, (err) => {
  if (err.response.status === 401) {
    auth.clear();
    window.location = '/auth/login';
  }
  return Promise.reject(err);
});

export default API;
