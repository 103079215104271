import React, { Component } from 'react';
import { Segment } from 'semantic-ui-react';

/**
 * @class {React.Component}
 */
class Container extends Component {
  render() {
    return (
      <Segment className="ax-full-height ax-centered" vertical>
        {this.props.children}
      </Segment>
    );
  }
}

export default Container;
