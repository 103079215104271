import auth from './auth';
import clients from './clients';
import projects from './projects';
import roles from './roles';
import settings from './settings';
import users from './users';

export default {
    auth,
    clients,
    projects,
    roles,
    settings,
    users,
};