import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import 'semantic-ui-css/semantic.min.css'

import { AuthContext } from './contexts';
import { Auth, i18n } from './services';
import Views from './views';
import * as serviceWorker from './serviceWorker';
import './index.css';

class Auditxess extends Component {

  /**
   * @property {Auth}
   */
  auth = new Auth();

  /**
   * @property {Object}
   */
  state = {};

  constructor(props) {
    super(props);
    this.state = (new Auth.Event(this.auth)).data;
    this.auth.addEventListener('auditxess.auth.change', this.authChangeListener.bind(this));
  }

  /**
   * @param  {Auth.Event} event
   * 
   * @return {void}
   */
  authChangeListener(event) {
    this.setState(event.data);
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    return (
      <React.StrictMode>
        <I18nextProvider i18n={i18n}>
          <AuthContext.Provider value={this.state}>
            <Views />
          </AuthContext.Provider>
        </I18nextProvider>
      </React.StrictMode>
    );
  }

}

ReactDOM.render(
  <Auditxess />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
