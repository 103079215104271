import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Message, Tab } from 'semantic-ui-react';

import { API } from '../../services';

class System extends Component {

  state = {
    input: {},
    loading: true,
    message: {
      field: {},
    },
  };

  constructor(props) {
    super(props);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.handleDismissSuccessMessage = this.messageDismissedListener('success').bind(this);
    this.handleInputChange = this.inputChangeListener.bind(this);
    this.handleSubmit = this.submitListener.bind(this);
  }

  /**
   * @return {void}
   */
  componentDidMount() {
    API
      .get('/api/v1/settings')
      .then((response) => {
        this.setState({
          input: response.data,
        });
      })
      .catch((reason) => {
        this.setState({
          message: {
            ...this.state.message,
            error: 'retrieve',
          },
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {InputOnChangeData}  data
   */
  inputChangeListener(event, { name, value }) {
    const { input } = this.state;
    input[name] = value;
    this.setState({ input });
  }

  /**
   * @param  {String} name
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({ message });
    };
  }

  /**
   * @param  {React.FormEvent} event
   */
  submitListener(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    API
      .post(`/api/v1/settings`, this.state.input)
      .then((response) => {
        this.setState({
          message: {
            ...this.state.message,
            success: 'system',
          },
        });
      })
      .catch((reason) => {
        this.setState({
          message: {
            ...this.state.message,
            error: 'default',
            field: reason.response.data.errors || {},
          },
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    const { t } = this.props;
    return (
      <Tab.Pane>
        <Form error={!!this.state.message.error} loading={this.state.loading} onSubmit={this.handleSubmit} success={!!this.state.message.success}>
          <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
          <Message
            content={t(`message.success.${this.state.message.success}.content`)}
            success
            icon='check'
            header={t(`message.success.${this.state.message.success}.title`)}
            onDismiss={this.handleDismissSuccessMessage}
          />

          <Form.Button color='blue' fluid type='submit'>
            {t('button.save')}
          </Form.Button>
        </Form>
      </Tab.Pane>
    );
  }

}

export default withTranslation('settings')(System);
