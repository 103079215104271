import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Message } from 'semantic-ui-react';

import { Content } from '../../components';
import { AuthContext } from '../../contexts';
import { API } from '../../services';

class ResetPassword extends Component {

  state = {
    input: {},
    loading: false,
    message: {
      field: {},
      info: 'resetPassword',
    },
  };

  constructor(props) {
    super(props);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.handleDismissSuccessMessage = this.messageDismissedListener('success').bind(this);
    this.handleInputChange = this.inputChangeListener.bind(this);
    this.handleSubmit = this.submitListener.bind(this);
  }

  /**
   * @return {void}
   */
  componentDidMount() {
    const { match: { params: { token } } } = this.props;
    this.setState({
      input: {
        ...this.state.input,
        token,
      },
    });
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {InputOnChangeData}  data
   */
  inputChangeListener(event, { name, value }) {
    const { input } = this.state;
    input[name] = value;
    this.setState({ input });
  }

  /**
   * @param  {String} name
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({ message });
    };
  }

  /**
   * @param  {React.FormEvent} event
   */
  submitListener(event) {
    event.preventDefault();
    this.setState({ loading: true });
    API
      .post(
        '/api/v1/auth/password/reset',
        this.state.input,
      )
      .then((response) => {
        this.setState({ input: {} });
        this.props.history.replace('/auth/login');
      })
      .catch((reason) => {
        const { message } = this.state;
        message.error = 'resetPassword';
        message.field = reason.response.data.errors || {};
        this.setState({ message });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  /**
   * @return {React.Component}
   */
  render() {
    const { t } = this.props;
    return (
      <Content.Auth message={this.state.message.info}>
        {
          this.state.message.success
          && <Message
            content={t(`message.success.${this.state.message.success}.content`)}
            icon='exclamation'
            header={t(`message.success.${this.state.message.success}.title`)}
            onDismiss={this.handleDismissSuccessMessage}
            success
          />
        }
        <Form error={!!this.state.message.error} onSubmit={this.handleSubmit} loading={this.state.loading}>
          <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
          <Form.Input
            type="password"
            autoComplete="new-password"
            error={
              this.state.message.field.new_password
              && {
                content: this.state.message.field.new_password.join("\n"),
                pointing: 'below'
              }
            }
            label={t('input.label.newPassword')}
            maxLength={128}
            minLength={3}
            name="new_password"
            onChange={this.handleInputChange}
            placeholder={t('input.placeholder.newPassword')}
            required
          />
          <Form.Input
            type="password"
            autoComplete="new-password"
            error={
              this.state.message.field.new_password_confirmation
              && {
                content: this.state.message.field.new_password_confirmation.join("\n"),
                pointing: 'below'
              }
            }
            label={t('input.label.newPasswordConfirmation')}
            maxLength={128}
            minLength={3}
            name="new_password_confirmation"
            onChange={this.handleInputChange}
            placeholder={t('input.placeholder.newPasswordConfirmation')}
            required
          />
          <Form.Button color='blue' fluid type='submit'>{t('button.resetPassword')}</Form.Button>
        </Form>
      </Content.Auth>
    );
  }
}

/**
 * @static {React.Context<Auth>}
 */
ResetPassword.contextType = AuthContext;

export default withTranslation('auth')(ResetPassword);
