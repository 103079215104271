import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Header, Message, Tab } from 'semantic-ui-react';

import { AuthContext } from '../../contexts';
import { API } from '../../services';

class Security extends Component {

  /**
   * @property {Object}
   */
  endpoints = {
    email: '',
    password: '/api/v1/settings',
  };

  /**
   * @property {Object}
   */
  state = {
    input: {
      email: {},
      password: {},
    },
    loading: false,
    message: {
      error: {},
      field: {
        email: {},
        password: {},
      },
      success: {},
    },
  };

  constructor(props) {
    super(props);
    this.handleDismissEmailSuccessMessage = this.messageDismissedListener('success', 'email').bind(this);
    this.handleDismissPasswordSuccessMessage = this.messageDismissedListener('success', 'password').bind(this);
    this.handleEmailInputChange = this.inputChangeListener('email').bind(this);
    this.handleEmailSubmit = this.submitListener('email').bind(this);
    this.handlePasswordInputChange = this.inputChangeListener('password').bind(this);
    this.handlePasswordSubmit = this.submitListener('password').bind(this);
  }

  /**
   * Called immediately after a component is mounted. Setting state here will trigger re-rendering.
   * 
   * @return {void} 
   */
  componentDidMount() {
    this.setState({
      input: {
        ...this.state.input,
        email: {
          email: this.context.user.email,
        },
      },
    });
  }

  /**
   * @param  {String}  form
   * 
   * @return {function(React.ChangeEvent, InputOnChangeData)}
   */
  inputChangeListener(form) {
    return (event, { name, value }) => {
      const { input } = this.state;
      input[form][name] = value;
      this.setState({ input });
    };
  }

  /**
   * @param  {String} name
   * @param  {String} form
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name, form) {
    return (event) => {
      const { message } = this.state;
      message[name][form] = null;
      this.setState({ message });
    };
  }

  /**
   * @param  {React.FormEvent} event
   */
  submitListener(form) {
    return (event) => {
      event.preventDefault();
      this.setState({
        loading: true,
      });
      API
        .post(this.endpoints[form], this.state.input[form])
        .then((response) => {
          this.setState({
            message: {
              ...this.state.message,
              success: {
                ...this.state.message.success,
                [form]: form,
              },
            },
          });
        })
        .catch((reason) => {
          const { message } = this.state;
          message.field[form] = reason.response.data.errors || {};
          this.setState({ message });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    };
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    const { t } = this.props;
    return (
      <Tab.Pane>
        <Header as='h5'>{t(`header.changeEmail`)}</Header>
        <Form error={!!this.state.message.error.email} onSubmit={this.handleEmailSubmit} success={!!this.state.message.success.email}>
          <Message
            content={t(`message.success.${this.state.message.success.email}.content`)}
            success
            icon='check'
            header={t(`message.success.${this.state.message.success.email}.title`)}
            onDismiss={this.handleDismissEmailSuccessMessage}
          />
          <Form.Input
            type="email"
            autoComplete="username"
            error={
              this.state.message.field.email.email
              && {
                content: this.state.message.field.email.email.join("\n"),
                pointing: 'below'
              }
            }
            label={t('auth:input.label.email')}
            maxLength={128}
            minLength={3}
            name="email"
            onChange={this.handleEmailInputChange}
            placeholder={t('auth:input.placeholder.email')}
            required
            value={this.state.input.email.email}
          />
          <Form.Button color='blue' disabled fluid type='submit'>
            {t('button.changeEmail')}
          </Form.Button>
        </Form>
        <Header as='h5'>{t(`header.changePassword`)}</Header>
        <Form error={!!this.state.message.error.password} onSubmit={this.handlePasswordSubmit} success={!!this.state.message.success.password}>
          <Message
            content={t(`message.success.${this.state.message.success.password}.content`)}
            success
            icon='check'
            header={t(`message.success.${this.state.message.success.password}.title`)}
            onDismiss={this.handleDismissPasswordSuccessMessage}
          />
          <Form.Input
            type="password"
            autoComplete="current-password"
            error={
              this.state.message.field.password.current_password
              && {
                content: this.state.message.field.password.current_password.join("\n"),
                pointing: 'below'
              }
            }
            label={t('input.label.currentPassword')}
            maxLength={128}
            minLength={3}
            name="current_password"
            onChange={this.handlePasswordInputChange}
            placeholder={t('input.placeholder.currentPassword')}
            required
          />
          <Form.Group widths="2">
            <Form.Input
              type="password"
              autoComplete="new-password"
              error={
                this.state.message.field.password.new_password
                && {
                  content: this.state.message.field.password.new_password.join("\n"),
                  pointing: 'below'
                }
              }
              label={t('auth:input.label.newPassword')}
              maxLength={128}
              minLength={3}
              name="new_password"
              onChange={this.handlePasswordInputChange}
              placeholder={t('auth:input.placeholder.newPassword')}
              required
            />
            <Form.Input
              type="password"
              autoComplete="new-password"
              error={
                this.state.message.field.password.new_password_confirmation
                && {
                  content: this.state.message.field.password.new_password_confirmation.join("\n"),
                  pointing: 'below'
                }
              }
              label={t('auth:input.label.newPasswordConfirmation')}
              maxLength={128}
              minLength={3}
              name="new_password_confirmation"
              onChange={this.handlePasswordInputChange}
              placeholder={t('auth:input.placeholder.newPasswordConfirmation')}
              required
            />
          </Form.Group>
          <Form.Button color='blue' fluid type='submit'>
            {t('button.changePassword')}
          </Form.Button>
        </Form>
      </Tab.Pane>
    );
  }

}

/**
 * @static {React.Context<Auth>}
 */
Security.contextType = AuthContext;

export default withTranslation('settings')(Security);