export default {
  button: {
    addPermission: 'Add Permission',
    cancel: 'Cancel',
    create: 'Create Role',
    done: 'Done',
    edit: 'Edit',
    removePermission: 'Remove',
    reset: 'Reset',
    save: 'Save',
  },
  input: {
    label: {
      name: 'Name',
      permission: 'Permission',
      permissions: 'Permissions',
    },
    placeholder: {
      name: 'The name of the role',
      permission: 'Choose a permission to add',
    },
  },
  message:{
    error: {
      fields: {
        content: 'Check your fields then try again.',
        title: 'Oops!',
      },
      retrieve: {
        content: 'Unable to get the required information to display here.',
        title: 'Error retrieving information',
      },
      server: {
        content: 'A problem occurred while communicating with the server. Please try again later.',
        title: 'Something went wrong!',
      },
      unexpected: {
        content: 'An unexpected issue occurred. Please try again later.',
        title: 'Something went wrong!',
      },
    },
    info: {},
    success: {
      create: {
        content: 'The new role has been added successfully.',
        title: 'Role created!',
      },
      edit: {
        content: 'The role has been updated successfully.',
        title: 'Update successful!',
      },
    },
  },
  title: {
    create: 'Create Role',
    edit: 'Edit {{name}} Role',
    index: 'Roles and Permissions',
  },
}