import i18n from 'i18next'
// import LanguageDetector from 'i18next-browser-languagedetector'
// import { initReactI18next } from 'react-i18next/hooks'

import resources from '../resources/lang'

i18n
  // .use(LanguageDetector)
  // .use(initReactI18next)
  .init({
    resources,
    interpolation: {
      escapeValue: false
    },
    debug: false,
    fallbackLng: 'en-US',
    lng: 'en-US',
    react: {
      wait: true
    }
  })

export default i18n
