import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { Content } from '../../components';

const labelRight = {
  position: 'right'
};

class Project extends React.Component {
  getOption() {
    return {
      title: {
          text: 'Analysis',
          subtext: 'From Account Analysis',
          sublink: 'http://e.weibo.com/1341556070/AjwF2AgQm',
      },
      tooltip: {
          trigger: 'axis',
          axisPointer: {
              type: 'shadow',
          },
      },
      grid: {
          top: 80,
          bottom: 30,
      },
      xAxis: {
          type: 'value',
          position: 'top',
          splitLine: {
              lineStyle: {
                  type: 'dashed',
              },
          },
      },
      yAxis: {
          type: 'category',
          axisLine: {show: false},
          axisLabel: {show: false},
          axisTick: {show: false},
          splitLine: {show: false},
          data: ['ten', 'nine', 'eight', 'seven', 'six', 'five', 'four', 'three', 'two', 'one'],
      },
      series: [
          {
              name: 'Credit vs Debit',
              type: 'bar',
              stack: 'Example',
              label: {
                  show: true,
                  formatter: '{b}',
              },
              data: [
                  {value: -0.07, label: labelRight},
                  {value: -0.09, label: labelRight},
                  0.2, 0.44,
                  {value: -0.23, label: labelRight},
                  0.08,
                  {value: -0.17, label: labelRight},
                  0.47,
                  {value: -0.36, label: labelRight},
                  0.18,
              ],
          },
      ],
    };
  }

  onChartReadyHandler() {
    console.debug('Chart Loaded...');
  }

  render() {
    const { match: { params: { projectId } } } = this.props;
    return (
      <Content title="Project">
        <p>{projectId}</p>
        <ReactEcharts
          option={this.getOption()}
          notMerge={true}
          lazyUpdate={true}
          // theme={"theme_name"}
          onChartReady={this.onChartReadyHandler.bind(this)}
          // onEvents={EventsDict}
          opts={{renderer: 'svg'}} />
      </Content>
    );
  }
}

export default Project;