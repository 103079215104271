import _ from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Message, Tab } from 'semantic-ui-react';

import { AuthContext } from '../../contexts';
import { API, Brreg, Logger } from '../../services';

class Company extends Component {
  /**
   * @property {Object}
   */
  state = {
    input: {
      additional_fields: {},
    },
    loading: true,
    message: {
      field: {},
    },
    options: {
      brreg: [],
    },
    search: {},
    processing: {},
  };

  /**
   * Create a new Company component
   * 
   * @param  {Readonly<P>}  props
   */
  constructor(props) {
    super(props);
    this.debouncedBrregSearch = this.fetchBrregOptions();
    this.handleBrregSearchChange = name => _.bindKey(this, 'breggSearchChangeListener', _, _, name);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.handleDismissSuccessMessage = this.messageDismissedListener('success').bind(this);
    this.handleDropDownChange = this.dropdownChangeListener.bind(this);
    this.handleInputChange = this.inputChangeListener.bind(this);
    this.handleSubmit = this.submitListener.bind(this);
  }

  /**
   * 
   * @param  {React.SyntheticEvent}  event
   * @param  {import('semantic-ui-react').DropdownOnSearchChangeData}  data
   * @param  {String} name
   * 
   * @return {void}
   */
  breggSearchChangeListener(event, { searchQuery }, name) {
    this.setState({
      search: {
        ...this.state.search,
        [name]: searchQuery,
      },
    });
    this.debouncedBrregSearch(name);
  }

  /**
   * @return {void}
   */
  componentDidMount() {
    if (Boolean(this.context.user.company_id)) {
      API
        .get(`/api/v1/companies/${this.context.user.company_id}`)
        .then((response) => {
          // Remove following section in next cycle
          // --START--
          response.data.additional_fields.brreg = response.data.additional_fields.altinn;
          delete response.data.additional_fields.altinn;
          // ---END---
          this.setState({
            input: {
              ...this.state.input,
              ...response.data,
            },
            options: {
              ...this.state.options,
              brreg: [
                {
                  key: 0,
                  text: response.data.name,
                  value: response.data.additional_fields.brreg,
                },
              ],
            },
          });
        })
        .catch((reason) => {
          Logger.error(reason);
          this.setState({
            message: {
              ...this.state.message,
              error: 'retrieve',
            },
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  /**
   * 
   * @param {React.ChangeEvent} event 
   * @param {import('semantic-ui-react').DropdownProps} data 
   */
  dropdownChangeListener(event, { name, value }) {
    const { input } = this.state;
    if (name === 'brreg') {
      const { postadresse: postAddr } = value;
      input.additional_fields.brreg = value;
      input.name = value.navn;
      if (Boolean(postAddr)) {
        input.postal_address = `${postAddr.adresse.join(' ')} ${postAddr.postnummer} ${postAddr.poststed} ${postAddr.land}`;
        input.zip_code = parseInt(postAddr.kommunenummer);
      }
    }
    this.setState({ input });
  }

  /**
   * @return {function(String): void & _.Cancelable}
   */
  fetchBrregOptions() {
    return _.debounce((name) => {
      const { processing, options } = this.state;
      processing[name] = true;
      this.setState({ processing });
      Brreg.get(
        '/enhetsregisteret/api/enheter',
        {
          params: {
            navn: this.state.search[name],
            page: 0,
            size: 50,
          },
        }
      )
        .then((response) => {
          const { page: { totalElements }, _embedded } = response.data;
          if (totalElements > 0) {
            options[name] = _embedded.enheter.map((company, index) => ({
              key: index,
              text: company.navn,
              value: company,
            }));
          } else {
            options[name] = [];
          }
          this.setState({ options });
        })
        .finally(() => {
          processing[name] = false;
          this.setState({ processing });
        });
    }, 300);
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {import('semantic-ui-react').InputOnChangeData}  data
   */
  inputChangeListener(event, { name, value }) {
    const { input } = this.state;
    const props = name.split('.');
    const prop = props.pop();
    props.reduce((p, c) => {
      if (p[c] === undefined) {
        p[c] = {};
      }
      return p[c];
    }, input)[prop] = value;
    this.setState({ input });
  }

  /**
   * @param  {String} name
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({ message });
    };
  }

  /**
   * @param  {React.FormEvent} event
   */
  submitListener(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    (
      Boolean(this.context.user.company_id)
        ? API.put(
          `/api/v1/companies/${this.context.user.company_id}`,
          this.state.input
        )
        : API.post(
          '/api/v1/companies',
          this.state.input
        )
    )
      .then((response) => {
        this.setState({
          message: {
            ...this.state.message,
            success: 'company',
          },
        });
        this.context.updateUser({
          ...this.context.user,
          company: response.data.id,
        });
      })
      .catch((reason) => {
        Logger.error(reason);
        this.setState({
          message: {
            ...this.state.message,
            error: 'default',
            field: reason.response.data.errors || {},
            success: undefined,
          },
        });
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    const { t } = this.props;
    return (
      <Tab.Pane>
        <Form error={!!this.state.message.error} loading={this.state.loading} onSubmit={this.handleSubmit} success={!!this.state.message.success}>
          <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
          <Message
            content={t(`message.success.${this.state.message.success}.content`)}
            success
            icon='check'
            header={t(`message.success.${this.state.message.success}.title`)}
            onDismiss={this.handleDismissSuccessMessage}
          />
          <Form.Dropdown
            error={
              this.state.message.field.brreg
              && {
                content: this.state.message.field.phone.join("\n"),
                pointing: 'below'
              }
            }
            fluid
            label={t('input.label.name')}
            loading={this.state.processing.brreg}
            name="brreg"
            noResultsMessage={t('input.message.noResult')}
            onChange={this.handleDropDownChange}
            onSearchChange={this.handleBrregSearchChange('brreg')}
            options={this.state.options.brreg}
            placeholder={t('input.placeholder.companyName')}
            scrolling
            selection
            search
            value={this.state.input.additional_fields.brreg}
          />
          <Form.Group widths="2">
            <Form.Input
              type="tel"
              error={
                this.state.message.field.phone
                && {
                  content: this.state.message.field.phone.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('input.label.phone')}
              name="phone"
              onChange={this.handleInputChange}
              placeholder={t('input.placeholder.companyPhone')}
              value={this.state.input.phone}
            />
            <Form.Input
              type="text"
              error={
                this.state.message.field.zip_code
                && {
                  content: this.state.message.field.zip_code.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('input.label.zipCode')}
              name="zip_code"
              onChange={this.handleInputChange}
              placeholder={t('input.placeholder.companyZipCode')}
              value={this.state.input.zip_code}
            />
          </Form.Group>
          <Form.Group widths="2">
            <Form.Input
              type="text"
              error={
                this.state.message.field.postal_address
                && {
                  content: this.state.message.field.postal_address.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('input.label.address')}
              name="postal_address"
              onChange={this.handleInputChange}
              placeholder={t('input.placeholder.companyAddress')}
              required
              value={this.state.input.postal_address}
            />
            <Form.Input
              type="text"
              label={t('input.label.organizationNumber')}
              name="additional_fields.brreg.organisasjonsnummer"
              placeholder={t('input.label.organizationNumber')}
              value={this.state.input.additional_fields.brreg && this.state.input.additional_fields.brreg.organisasjonsnummer}
            />
          </Form.Group>
          <Form.Group widths="2">
            <Form.Input
              type="text"
              error={
                this.state.message.field.altinn_user_email
                && {
                  content: this.state.message.field.altinn_user_email.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('input.label.altinnUserEmail')}
              name="additional_fields.altinn_user.email"
              onChange={this.handleInputChange}
              placeholder={t('input.placeholder.altinnUserEmail')}
              value={this.state.input.additional_fields.altinn_user && this.state.input.additional_fields.altinn_user.email}
            />
            <Form.Input
              type="text"
              error={
                this.state.message.field.altinn_user_password
                && {
                  content: this.state.message.field.altinn_user_password.join("\n"),
                  pointing: 'below',
                }
              }
              label={t('input.label.altinnUserPassword')}
              name="additional_fields.altinn_user.password"
              onChange={this.handleInputChange}
              placeholder={t('input.placeholder.altinnUserPassword')}
              value={this.state.input.additional_fields.altinn_user && this.state.input.additional_fields.altinn_user.password}
            />
          </Form.Group>
          <Form.Input
            type="text"
            error={
              this.state.message.field.tripletex_acc_key
              && {
                content: this.state.message.field.tripletex_acc_key.join("\n"),
                pointing: 'below',
              }
            }
            label={t('input.label.tripletexAccountantKey')}
            name="additional_fields.tripletex.accountantKey"
            onChange={this.handleInputChange}
            placeholder={t('input.placeholder.tripletexAccountantKey')}
            value={this.state.input.additional_fields.tripletex && this.state.input.additional_fields.tripletex.accountantKey}
          />
          <Form.Button color='blue' fluid type='submit'>
            {t('button.save')}
          </Form.Button>
        </Form>
      </Tab.Pane>
    );
  }

}

/**
 * @static {React.Context<Auth>}
 */
Company.contextType = AuthContext;

export default withTranslation('settings')(Company);
