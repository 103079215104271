import React from 'react';
import { Menu } from 'semantic-ui-react';

import { AuthContext } from '../contexts';

class Topbar extends React.Component {

  constructor(props) {
    super(props);
    this.handleLogout = this.logoutClickListener.bind(this);
  }

  /**
   * @param  {React.MouseEvent}                           event
   * @param  {import('semantic-ui-react').MenuItemProps}  data
   */
  logoutClickListener(event, data) {
    this.context.clear();
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    return (
      <Menu secondary>
        <Menu.Menu position='right'>
          <Menu.Item
            active={false}
            name='logout'
            onClick={this.handleLogout}
          />
        </Menu.Menu>
      </Menu>
    );
  }
}

/**
 * @property  {React.Context<Auth>}
 */
Topbar.contextType = AuthContext;

export default Topbar;