import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Image, List, Message } from 'semantic-ui-react';

import Create from './Create';
import Edit from './Edit';
import { Content } from '../../components';
import { API } from '../../services';

class Users extends Component {
  /**
   * @property {Object}
   */
  state = {
    creating: false,
    editing: false,
    loading: true,
    message: {},
    users: [],
  };

  /**
   * @param {Readonly<Object>} props
   * @constructs Roles
   */
  constructor(props) {
    super(props);
    this.handleCreateClose = this.createCloseListener.bind(this);
    this.handleEditClose = this.editCloseListener.bind(this);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.pageActions = [
      {
        handler: this.createOpenListener.bind(this),
        label: props.t('button.create'),
      },
    ];
  }

  /**
   * @return {void}
   */
  componentDidMount() {
    API
      .get('/api/v1/users')
      .then((response) => {
        this.setState({
          users: response.data,
        });
      })
      .catch((reason) => {
        this.setState({
          message: {
            ...this.state.message,
            error: 'retrieve',
          },
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  /**
   * @param  {React.MouseEvent} event 
   * 
   * @return {void}
   */
  createCloseListener(event) {
    this.setState({ creating: false });
  }

  /**
   * @param  {React.MouseEvent} event 
   * 
   * @return {void}
   */
  createOpenListener(event) {
    this.setState({ creating: true });
  }

  /**
   * @param  {React.MouseEvent} event 
   * 
   * @return {void}
   */
  editCloseListener(event) {
    this.setState({ editing: false });
  }

  /**
   * 
   * @param {String} userId 
   * @param {React.MouseEvent} event 
   */
  editOpenListener(userId, event) {
    this.setState({
      editing: userId,
    });
  }

  /**
   * @param  {String} name
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({ message });
    };
  }

  render() {
    const { t } = this.props;
    return (
      <Content actions={this.pageActions} loading={this.state.loading} title={t('title.index')}>
        {
          this.state.message.error
          && <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
        }
        <List divided verticalAlign='middle'>
          {
            this.state.users.map(
              (user, index) => (
                <List.Item key={`user${index}`}>
                  <List.Content floated='right'>
                    <Button onClick={this.editOpenListener.bind(this, user.id)}>{t('button.edit')}</Button>
                  </List.Content>
                  <Image avatar src='/favicon.ico' />
                  <List.Content>
                    <List.Header>{user.first_name} {user.last_name}</List.Header>
                    <List.Description>{user.email}</List.Description>
                  </List.Content>
                </List.Item>
              )
            )
          }
        </List>
        <Create onClose={this.handleCreateClose} open={this.state.creating} />
        <Edit onClose={this.handleEditClose} open={typeof this.state.editing === 'string'} userId={this.state.editing} />
      </Content>
    );
  }
}

export default withTranslation('users')(Users);
