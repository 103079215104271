import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import List from './List';
import Role from './Role';

class Roles extends Component {
  /**
   * @return {React.ReactNode}
   */
  render() {
    const { match: { url } } = this.props;
    return (
      <Switch>
        <Route component={List} exact path={url} />
        <Route component={Role} path={`${url}/:roleId`} />
      </Switch>
    );
  }
}

export default Roles;
