export default {
  button: {
    addStaff: 'Add Staff',
    more: 'View More',
    cancel: 'Cancel',
    create: 'Add Client',
    done: 'Done',
    edit: 'Edit',
    removeStaff: 'Remove',
    reset: 'Reset',
    save: 'Save',
  },
  input: {
    label: {
      client: 'Client',
      name: 'Name',
      staff: 'Staff',
      users: 'Assigned Staff',
    },
    message: {
      noResult: 'No results found',
    },
    placeholder: {
      client: 'Search for a client',
      name: 'The name of the client',
      staff: 'Select a user to assign to the client',
    },
  },
  message:{
    error: {
      client: {
        content: 'Your request could not be completed. Please ensure you have the correct settings for your account.',
        title: 'Unprocessable Request!',
      },
      fields: {
        content: 'Check your fields then try again.',
        title: 'Oops!',
      },
      retrieve: {
        content: 'Unable to get the required information to display here.',
        title: 'Error retrieving information',
      },
      server: {
        content: 'A problem occurred while communicating with the server. Please try again later.',
        title: 'Something went wrong!',
      },
      unexpected: {
        content: 'An unexpected issue occurred. Please try again later.',
        title: 'Something went wrong!',
      },
    },
    info: {},
    success: {
      create: {
        content: 'The new client has been added successfully.',
        title: 'Client created!',
      },
      edit: {
        content: 'The client has been updated successfully.',
        title: 'Update successful!',
      },
    },
    warn: {
      empty: {
        content: 'Nothing here yet. Create one by clicking the button above',
        title: 'Oops!',
      },
    },
  },
  title: {
    create: 'Add Client',
    edit: 'Edit {{name}}',
    index: 'Clients',
  },
};