export default {
  button: {
    changeEmail: 'Change Email',
    changePassword: 'Change Password',
    save: 'Save',
  },
  header: {
    changeEmail: 'Change your email',
    changePassword: 'Change your password'
  },
  input: {
    label: {
      address: 'Address',
      altinnUserEmail: 'Altinn Username',
      altinnUserPassword: 'Altinn Password',
      birthday: 'Birthday',
      companySearch: 'Search Company',
      currentPassword: 'Current Password',
      name: 'Name',
      organizationNumber: 'Organization Number',
      phone: 'Phone Number',
      tripletexAccountantKey: 'Tripletex Accountant\'s Key',
      zipCode: 'Zip Code',
    },
    message: {
      noResult: 'No results found',
    },
    placeholder: {
      altinnUserEmail: 'Your Altinn username',
      altinnUserPassword: 'Your Altinn password',
      birthday: 'Your birthday',
      companyAddress: 'The postal address for the company',
      companyName: 'The company\'s name',
      companyPhone: 'The company\'s phone number',
      companySearch: 'Your company',
      companyZipCode: 'The company\'s address zip code',
      currentPassword: 'Your current password',
      organizationNumber: 'Your organization\'s number',
      tripletexAccountantKey: 'Your Tripletex accountant\'s key',
      phone: 'Your phone number',
    },
  },
  message: {
    error: {
      default: {
        content: 'Check your fields then try again.',
        title: 'Something went wrong!',
      },
      retrieve: {
        content: 'Unable to get the required information to display here.',
        title: 'Error retrieving information',
      },
    },
    info: {
      default: {
        content: 'Fields marked with a " * " are required',
        title: 'Note:',
      },
    },
    success: {
      company: {
        content: 'Your company details were updated.',
        title: 'Company Updated!',
      },
      email: {
        content: 'A link has been sent to verify your new email. Please check your inbox',
        title: 'Link sent!',
      },
      password: {
        content: 'You can now use your new password whenever you log in.',
        title: 'Password Changed!',
      },
      profile: {
        content: 'Your profile was updated.',
        title: 'Profile Updated!',
      },
      system: {
        content: 'The system settings were updated.',
        title: 'System Settings Updated!',
      },
    },
  },
  pane: {
    company: 'Company',
    profile: 'Profile',
    security: 'Security',
    system: 'System',
  },
  title: 'Settings',
};
