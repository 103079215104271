export default {
  button: {
    login: 'Sign In',
    register: 'Join Auditxess',
    resetPassword: 'Reset Password',
    sendLink: 'Send Link',
    setPassword: 'Set Password',
    singleSignOn: 'Digital Revisor SSO',
  },
  image: {
    alt: {
      logo: 'Digital Revisor Logo',
    },
  },
  input: {
    label: {
      agree: 'By clicking "Join Auditxess", I agree to the Terms of Use and Privacy Policy linked below',
      email: 'Email',
      firstName: 'First Name',
      lastName: 'Last Name',
      newPassword: 'New Password',
      newPasswordConfirmation: 'Confirm New Password',
      password: 'Password',
      passwordConfirmation: 'Confirm Password',
      remember: 'Keep me signed in',
    },
    placeholder: {
      email: 'Your email address',
      firstName: 'Your given name',
      lastName: 'Your family name',
      newPassword: 'Your new password',
      newPasswordConfirmation: 'Your new password again',
      password: 'Your password',
      passwordConfirmation: 'Your password again',
    },
  },
  link: {
    forgotPassword: 'Click here',
    login: 'Sign In',
    privacyPolicy: 'Privacy Policy',
    register: 'Sign Up',
    tos: 'Terms of Use',
  },
  message: {
    error: {
      default: {
        content: 'An unexpected issue occurred. Please try again later.',
        title: 'Something went wrong!',
      },
      login: {
        content: 'The email or password you provided is incorrect. Please check the fields and try again.',
        title: 'Incorrect email or password given!',
      },
      register: {
        content: 'Check your fields then try again.',
        title: 'Something went wrong!',
      },
      sso: {
        content: 'An unexpected issue occurred when signing in. Please try again later.',
        title: 'Something went wrong!',
      },
    },
    info: {
      login: {
        content: 'Sign in below to continue...',
        title: 'Hi there!',
      },
      register: {
        content: 'You seem new here. Please set up your account by filling in the form below.',
        title: 'Welcome back!',
      },
      resetPassword: {
        content: 'You can now set a new password for your account below.',
        title: 'Good news!',
      },
      sendLink: {
        content: 'This happens to the best of us. Fill in your email and we will send you a link to reset your password.',
        title: 'Do not worry!',
      },
      setPassword: {
        content: 'To complete verifying your email, please set a password for your account below.',
        title: 'Almost done!',
      },
    },
    success: {
      sendLink: {
        content: 'Open the link we have just sent to your email to reset your password.',
        title: 'Link sent!',
      },
    },
    forgotPassword: 'Forgot your password?',
    login: 'Already have an account?',
    register: 'Don\'t have an account?',
  },
  title: {
    dashboard: 'Dashboard',
    login: 'Sign In',
    register: 'Sign Up',
    resetPassword: 'Reset Account Password',
    sendLink: 'Send Reset Link',
    setPassword: 'Set Account Password',
  },
};
