import axios from 'axios';

/**
 * @const {AxiosInstance}
 */
const Brreg = axios.create({
  baseURL: 'https://data.brreg.no',
});

export default Brreg;
