import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form, Message } from 'semantic-ui-react'

import { Content } from '../../components';
import { AuthContext } from '../../contexts';
import { API } from '../../services';

class Register extends Component {

  state = {
    input: {},
    loading: false,
    message: {
      field: {},
      info: 'register',
    },
  };
  profileFields = {
    email: 'email',
    first_name: 'given_name',
    last_name: 'family_name',
  };
  staticFields = ['email', 'first_name', 'gluu_user', 'last_name'];

  constructor(props) {
    super(props);
    this.handleCheckboxChange = this.checkboxChangeListener.bind(this);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.handleInputChange = this.inputChangeListener.bind(this);
    this.handleSubmit = this.submitListener.bind(this);
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {CheckboxProps}  data
   */
  checkboxChangeListener(event, { checked, name }) {
    const { input } = this.state;
    input[name] = checked ? 'On' : 'Off';
    this.setState({ input });
  }

  /**
   * @return {void}
   */
  componentDidMount() {
    const { input } = this.state;
    const { profile = {} } = this.context;
    Object.keys(this.profileFields).forEach((name) => {
      document.getElementsByName(name).forEach((field) => {
        const { [this.profileFields[name]]: value } = profile;
        field.value = value;
      });
    });
    this.staticFields.forEach((name) => {
      document.getElementsByName(name).forEach(({ value }) => {
        input[name] = value;
      });
    });
    this.setState({ input });
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {InputOnChangeData}  data
   */
  inputChangeListener(event, { name, value }) {
    const { input } = this.state;
    input[name] = value;
    this.setState({ input });
  }

  /**
   * @param  {String} name
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({ message });
    };
  }

  /**
   * @param  {React.FormEvent} event
   */
  submitListener(event) {
    event.preventDefault();
    this.setState({ loading: true });
    API
      .post('/api/v1/auth/register', this.state.input)
      .then((response) => {
        const { token: credential, user } = response.data;
        this.setState({ input: {} });
        this.context.clear();
        this.context.authenticate({ credential, user });
        this.props.history.replace('/');
      })
      .catch((reason) => {
        const { message } = this.state;
        message.error = 'register';
        message.field = reason.response.data.errors || {};
        this.setState({ message });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    const { t } = this.props;
    const { profile = {} } = this.context;
    return (
      <Content.Auth message={this.state.message.info}>
        <Form error={!!this.state.message.error} onSubmit={this.handleSubmit} loading={this.state.loading}>
          <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
          <Form.Input
            type="hidden"
            name="gluu_user"
            onChange={this.handleInputChange}
            value={JSON.stringify(profile)}
          />
          <Form.Input
            type="text"
            autoComplete="given-name"
            error={
              this.state.message.field.first_name
              && {
                content: this.state.message.field.first_name.join("\n"),
                pointing: 'below',
              }
            }
            label={t('input.label.firstName')}
            name="first_name"
            onChange={this.handleInputChange}
            placeholder={t('input.placeholder.firstName')}
            readOnly
            required
          />
          <Form.Input
            type="text"
            autoComplete="family-name"
            error={
              this.state.message.field.last_name
              && {
                content: this.state.message.field.last_name.join("\n"),
                pointing: 'below',
              }
            }
            label={t('input.label.lastName')}
            maxLength={30}
            name="last_name"
            onChange={this.handleInputChange}
            placeholder={t('input.placeholder.lastName')}
          />
          <Form.Input
            type="email"
            autoComplete="username"
            error={
              this.state.message.field.email
              && {
                content: this.state.message.field.email.join("\n"),
                pointing: 'below',
              }
            }
            label={t('input.label.email')}
            name="email"
            onChange={this.handleInputChange}
            placeholder={t('input.placeholder.email')}
            readOnly
            required
          />
          <Form.Input
            type="password"
            autoComplete="new-password"
            error={
              this.state.message.field.password
              && {
                content: this.state.message.field.password.join("\n"),
                pointing: 'below',
              }
            }
            label={t('input.label.password')}
            minLength={8}
            name="password"
            onChange={this.handleInputChange}
            placeholder={t('input.placeholder.password')}
          />
          <Form.Input
            type="password"
            autoComplete="new-password"
            error={
              this.state.message.field.password_confirmation
              && {
                content: this.state.message.field.password_confirmation.join("\n"),
                pointing: 'below',
              }
            }
            label={t('input.label.passwordConfirmation')}
            minLength={8}
            name="password_confirmation"
            onChange={this.handleInputChange}
            placeholder={t('input.placeholder.passwordConfirmation')}
          />
          <Form.Checkbox
            error={
              this.state.message.field.agree
              && {
                content: this.state.message.field.agree.join("\n"),
                pointing: 'below',
              }
            }
            label={t('input.label.agree')}
            name="agree"
            onChange={this.handleCheckboxChange}
            required
          />
          <Form.Button color='blue' fluid type='submit'>
            {t('button.register')}
          </Form.Button>
        </Form>
        <p>
          {t('message.login')} <Link to="/auth/login">{t('link.login')}</Link>
        </p>
        <p>
          <a href="https://digitalrevisor.no/new/termsandprivacy.html">{t('link.tos')} &amp; {t('link.privacyPolicy')}</a>
        </p>
      </Content.Auth>
    );
  }
}

/**
 * @static {React.Context<Auth>}
 */
Register.contextType = AuthContext;


export default withTranslation('auth')(Register);
