import _ from 'lodash';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Grid, Icon, Message } from 'semantic-ui-react';

import { Content } from '../../components';
import { API, FormErrorHandler } from '../../services';

class Role extends Component {
  /**
   * @property {Objact}
   */
  state = {
    initialInput: {},
    input: {
      permissions: [null],
    },
    loading: false,
    message: {
      field: {},
    },
    permissions: [],
  };

  /**
   * @param  {Readonly<Object>}  props
   * @constructs Create
   */
  constructor(props) {
    super(props);
    this.handleAddPermission = this.addPermissionListener.bind(this);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.handleDismissSuccessMessage = this.messageDismissedListener('success').bind(this);
    this.handleInputArrayChange = index => _.bindKey(this, 'handleInputChange', _, _, index);
    this.handleInputChange = this.inputChangeListener.bind(this);
    this.handleRemovePermission = this.removePermissionListener.bind(this);
    this.handleReset = this.resetListener.bind(this);
    this.handleSubmit = this.submitListener.bind(this);
  }

  /**
   * @param {React.MouseEvent} event
   */
  addPermissionListener(event) {
    this.setState({
      input: {
        ...this.state.input,
        permissions: [
          null,
          ...this.state.input.permissions,
        ],
      }
    });
  }

  /**
   * @return {void}
   */
  componentDidMount() {
    if (!this.state.loading) {
      this.setState({
        loading: true,
      });
      API
        .get('/api/v1/permissions')
        .then((response) => {
          this.setState({
            permissions: response.data.map(
              (permission, index) => ({
                key: index,
                text: permission.name,
                value: permission.id,
              })
            ),
          });
          return API.get(`/api/v1/roles/${this.props.match.params.roleId}`);
        })
        .then((response) => {
          this.setState({
            input: Object.assign(this.state.input, response.data),
            initialInput: Object.assign({}, response.data),
          });
        })
        .catch((reason) => {
          this.setState({
            input: {
              permissions: [null]
            },
            message: {
              ...this.state.message,
              error: 'retrieve',
            },
            permissions: [],
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {InputOnChangeData}  data
   * @param  {String}  data.name
   * @param  {number}  index
   * @param  {any}  data.value
   */
  inputChangeListener(event, { name, value }, index) {
    const { input } = this.state;
    if (index !== undefined) {
      input[name][index] = value;
    } else {
      input[name] = value;
    }
    this.setState({ input });
  }

  /**
   * @param  {String} name
   *
   * @return {Function(event: React.MouseEvent): void}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({
        message: {
          ...this.state.message,
          [name]: null,
        },
      });
    };
  }

  /**
   * @param  {Object}  errors
   * 
   * @return {void}
   */
  onFieldError(errors) {
    this.setState({
      message: {
        error: 'fields',
        success: null,
        field: errors,
      },
    });
  }

  /**
   * @return {void}
   */
  onServerError() {
    this.setState({
      message: {
        error: 'server',
        success: null,
        field: {},
      },
    });
  }

  /**
   * @return {void}
   */
  onUnknownError() {
    this.setState({
      message: {
        error: 'unexpected',
        success: null,
        field: {},
      },
    });
  }

  /**
   * @param  {number}  index
   * 
   * @return {Function(event: React.MouseEvent): void}
   */
  removePermissionListener(index) {
    return (event) => {
      const { input } = this.state;
      input.permissions.splice(index, 1);
      this.setState({ input });
    };
  }

  /**
   * @param  {React.FormEvent}  event
   * 
   * @return {void}
   */
  resetListener(event) {
    event.preventDefault();
    this.setState({
      input: Object.assign({}, this.state.initialInput),
    });
  }

  /**
   * @param  {React.FormEvent}  event
   * 
   * @return {void}
   */
  submitListener(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    API
      .put(
        `/api/v1/roles/${this.props.match.params.roleId}`,
        this.state.input
      )
      .then((response) => {
        this.setState({
          input: Object.assign({}, response.data),
          initialInput: Object.assign({}, response.data),
          message: {
            error: null,
            field: {},
            success: 'edit',
          },
        });
      })
      .catch((reason) => {
        FormErrorHandler(
          reason,
          {
            onFieldError: this.onFieldError.bind(this),
            onServerError: this.onServerError.bind(this),
            onUnknownError: this.onUnknownError.bind(this),
          }
        );
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    const { t } = this.props;
    return (
      <Content title={t('title.edit', { name: this.state.initialInput.name })}>
        <Form
          error={this.state.message.error}
          loading={this.state.loading}
          onReset={this.handleReset}
          onSubmit={this.handleSubmit}
          success={this.state.message.success}
        >
          <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
          <Message
            content={t(`message.success.${this.state.message.success}.content`)}
            success
            icon='check'
            header={t(`message.success.${this.state.message.success}.title`)}
            onDismiss={this.handleDismissSuccessMessage}
          />
          <Grid>
            <Grid.Column width={5}>
              <Form.Input
                type="text"
                error={
                  this.state.message.field.name
                  && {
                    content: this.state.message.field.name.join("\n"),
                    pointing: 'below',
                  }
                }
                label={t('input.label.name')}
                name="name"
                onChange={this.handleInputChange}
                placeholder={t('input.placeholder.name')}
                required
                value={this.state.input.name}
              />
              <Form.Button onClick={this.handleAddPermission} type="button">
                <Icon name='plus' />{t('button.addPermission')}
              </Form.Button>
            </Grid.Column>
            <Grid.Column width={11}>
              {
                this.state.input.permissions.map(
                  (permission, index) => (
                    <Form.Group key={`permissionSet${index}`}>
                      <Form.Dropdown
                        type="text"
                        error={
                          this.state.message.field[`permissions.${index}`]
                          && {
                            content: this.state.message.field[`permissions.${index}`].join("\n"),
                            pointing: 'below',
                          }
                        }
                        fluid
                        label={t('input.label.permission')}
                        name="permissions"
                        options={this.state.permissions}
                        onChange={this.handleInputArrayChange(index)}
                        placeholder={t('input.placeholder.permission')}
                        required
                        search
                        selection
                        value={permission}
                        width={14}
                      />
                      <Form.Button
                        color="red"
                        label={t('button.removePermission')}
                        icon="cancel"
                        onClick={this.handleRemovePermission(index)}
                        type="button"
                        width={2}
                      />
                    </Form.Group>
                  )
                )
              }
            </Grid.Column>
          </Grid>
          <Form.Group floated="right">
            <Form.Button color='blue' type="reset">
              <Icon name='refresh' /> {t('button.reset')}
            </Form.Button>
            <Form.Button color='green' type="submit">
              <Icon name='checkmark' /> {t('button.done')}
            </Form.Button>
          </Form.Group>
        </Form>
      </Content>
    );
  }
}

export default withTranslation('roles')(Role);