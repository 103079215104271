import PropTypes from 'prop-types';
import React from 'react';
import { Button, Container, Dimmer, Header, Loader, Segment } from 'semantic-ui-react';

import Auth from './Auth';
import './style.css';

class Content extends React.Component {
  /**
   * @return {React.ReactNode}
   */
  render() {
    return (
      <Container>
        <Dimmer active={Boolean(this.props.loading)}>
          <Loader />
        </Dimmer>
        <Segment basic clearing>
          {
            this.props.title &&
            <Header as='h2' dividing floated="left">
              {this.props.title}
            </Header>
          }
          {
            this.props.actions &&
            <Button.Group floated="right">
              {this.props.actions.map(
                (config, index) => (
                  <Button color={config.color || 'blue'} key={`actionButton${index}`} onClick={config.handler}>{config.label}</Button>
                )
              )}
            </Button.Group>
          }
        </Segment>
        {this.props.children}
      </Container>
    );
  }
}

/**
 * @static {React.Component}
 */
Content.Auth = Auth;

/**
 * @static {Readonly<Object>}
 */
Content.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.oneOf([
        'blue',
        'red',
        'orange',
        'yellow',
        'olive',
        'green',
        'teal',
        'violet',
        'purple',
        'pink',
        'brown',
        'grey',
        'black',
        'facebook',
        'google plus',
        'vk',
        'twitter',
        'linkedin',
        'instagram',
        'youtube'
      ]),
      handler: PropTypes.func.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  loading: PropTypes.bool,
  title: PropTypes.string,
};

export default Content;