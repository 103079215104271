import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';

import { API, Brreg, FormErrorHandler, Logger } from '../../services';

class Create extends Component {
  /**
   * @property {Objact}
   */
  state = {
    input: {
      staff: [null],
    },
    loading: false,
    message: {
      field: {},
    },
    options: {
      reportee_id: [],
    },
    processing: {},
    search: {},
    users: [],
  };

  /**
   * @param  {Readonly<Object>}  props
   * @constructs Create
   */
  constructor(props) {
    super(props);
    this.debouncedBrregSearch = this.fetchBrregOptions();
    this.handleAddStaff = this.addStaffListener.bind(this);
    this.handleBrregSearchChange = name => _.bindKey(this, 'breggSearchChangeListener', _, _, name);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.handleDismissSuccessMessage = this.messageDismissedListener('success').bind(this);
    this.handleDropDownChange = this.dropdownChangeListener.bind(this);
    this.handleInputArrayChange = index => _.bindKey(this, 'handleInputChange', _, _, index);
    this.handleInputChange = this.inputChangeListener.bind(this);
    this.handleMount = this.mountListener.bind(this);
    this.handleRemoveStaff = this.removeStaffListener.bind(this);
    this.handleReset = this.resetListener.bind(this);
    this.handleSubmit = this.submitListener.bind(this);
    this.handleUnmount = this.unmountListener.bind(this);
  }

  /**
   * @param {React.MouseEvent} event
   */
  addStaffListener(event) {
    this.setState({
      input: {
        ...this.state.input,
        staff: [
          null,
          ...this.state.input.staff,
        ],
      }
    });
  }

  /**
   * 
   * @param  {React.SyntheticEvent}  event
   * @param  {import('semantic-ui-react').DropdownOnSearchChangeData}  data
   * @param  {String} name
   * 
   * @return {void}
   */
  breggSearchChangeListener(event, { searchQuery }, name) {
    this.setState({
      search: {
        ...this.state.search,
        [name]: searchQuery,
      },
    });
    this.debouncedBrregSearch(name);
  }

  /**
   * 
   * @param {React.ChangeEvent} event 
   * @param {import('semantic-ui-react').DropdownProps} data 
   */
  dropdownChangeListener(event, { name, value }) {
    this.setState({
      input: {
        ...this.state.input,
        [name]: value,
      },
    });
  }

  /**
   * @return {function(String): void & _.Cancelable}
   */
  fetchBrregOptions() {
    return _.debounce((name) => {
      const { processing, options } = this.state;
      processing[name] = true;
      this.setState({ processing });
      Brreg.get(
        '/enhetsregisteret/api/enheter',
        {
          params: {
            navn: this.state.search[name],
            page: 0,
            size: 50,
          },
        }
      )
        .then((response) => {
          const { page: { totalElements }, _embedded } = response.data;
          if (totalElements > 0) {
            options[name] = _embedded.enheter.map((company, index) => ({
              key: index,
              text: company.navn,
              value: company.organisasjonsnummer,
            }));
          } else {
            options[name] = [];
          }
          this.setState({ options });
        })
        .finally(() => {
          processing[name] = false;
          this.setState({
            processing: {
              ...this.state.processing,
              [name]: false,
            },
          });
        });
    }, 300);
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {InputOnChangeData}  data
   * @param  {String}  data.name
   * @param  {number}  index
   * @param  {any}  data.value
   */
  inputChangeListener(event, { name, value }, index) {
    const { input } = this.state;
    Logger.debug(`Indexed: ${index}`);
    if (index !== undefined) {
      input[name][index] = value;
    } else {
      input[name] = value;
    }
    this.setState({ input });
  }

  /**
   * @param  {String} name
   *
   * @return {Function(event: React.MouseEvent): void}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({
        message: {
          ...this.state.message,
          [name]: null,
        },
      });
    };
  }

  /**
   * @param  {null}  nothing
   * @param  {ModalProps}  data
   * 
   * @return {void}
   */
  mountListener(nothing, data) {
    if (!this.state.loading) {
      this.setState({
        loading: true,
      });
      API
        .get('/api/v1/users')
        .then((response) => {
          this.setState({
            users: response.data.map(
              (user, index) => ({
                key: index,
                text: `${user.first_name} <${user.email}>`,
                value: user.id,
              })
            ),
          });
        })
        .catch((reason) => {
          this.setState({
            message: {
              ...this.state.message,
              error: 'retrieve',
            },
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }
  }

  /**
   * @return {void}
   */
  onClientError() {
    this.setState({
      message: {
        error: 'client',
        success: null,
        field: {},
      },
    });
  }

  /**
   * @param  {Object}  errors
   * 
   * @return {void}
   */
  onFieldError(errors) {
    this.setState({
      message: {
        error: 'fields',
        success: null,
        field: errors,
      },
    });
  }

  /**
   * @return {void}
   */
  onServerError() {
    this.setState({
      message: {
        error: 'server',
        success: null,
        field: {},
      },
    });
  }

  /**
   * @return {void}
   */
  onUnknownError() {
    this.setState({
      message: {
        error: 'unexpected',
        success: null,
        field: {},
      },
    });
  }

  /**
   * @param  {number}  index
   * 
   * @return {Function(event: React.MouseEvent): void}
   */
  removeStaffListener(index) {
    return (event) => {
      const { input } = this.state;
      input.staff.splice(index, 1);
      this.setState({ input });
    };
  }

  /**
   * @param  {React.FormEvent}  event
   * 
   * @return {void}
   */
  resetListener(event) {
    event.preventDefault();
    this.setState({
      input: {
        staff: [null],
      },
    });
  }

  /**
   * @param  {React.FormEvent}  event
   * 
   * @return {void}
   */
  submitListener(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    API
      .post('/api/v1/clients', this.state.input)
      .then((reponse) => {
        this.setState({
          input: {
            name: null,
            users: [null],
          },
          message: {
            error: null,
            field: {},
            success: 'create',
          },
        });
      })
      .catch((reason) => {
        FormErrorHandler(
          reason,
          {
            onClientError: this.onClientError.bind(this),
            onFieldError: this.onFieldError.bind(this),
            onServerError: this.onServerError.bind(this),
            onUnknownError: this.onUnknownError.bind(this),
          }
        );
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * @param  {null}  nothing
   * @param  {SemanticUI.ModalProps}  data
   * 
   * @return {void}
   */
  unmountListener(nothing, data) {
    this.setState({
      input: {
        staff: [null],
      },
      message: {
        field: {},
      },
      users: [],
    });
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    const { onClose, open, t } = this.props;
    return (
      <Modal
        as={Form}
        closeIcon={!this.state.loading}
        closeOnDimmerClick={!this.state.loading}
        closeOnDocumentClick={!this.state.loading}
        error={Boolean(this.state.message.error)}
        onClose={onClose}
        onMount={this.handleMount}
        onReset={this.handleReset}
        onSubmit={this.handleSubmit}
        onUnmount={this.handleUnmount}
        open={open}
        success={Boolean(this.state.message.success)}
      >
        <Dimmer active={this.state.loading} inverted>
          <Loader />
        </Dimmer>
        <Header icon='add' content={t('title.create')} />
        <Modal.Content scrolling>
          <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
          <Message
            content={t(`message.success.${this.state.message.success}.content`)}
            success
            icon='check'
            header={t(`message.success.${this.state.message.success}.title`)}
            onDismiss={this.handleDismissSuccessMessage}
          />
          <Grid>
            <Grid.Column width={5}>
              <Form.Dropdown
                error={
                  this.state.message.field.reportee_id
                  && {
                    content: this.state.message.field.reportee_id.join("\n"),
                    pointing: 'below'
                  }
                }
                fluid
                label={t('input.label.client')}
                loading={this.state.processing.reportee_id}
                name="reportee_id"
                noResultsMessage={t('input.message.noResult')}
                onChange={this.handleDropDownChange}
                onSearchChange={this.handleBrregSearchChange('reportee_id')}
                options={this.state.options.reportee_id}
                placeholder={t('input.placeholder.client')}
                scrolling
                selection
                search
                value={this.state.input.reportee_id}
              />
              <Form.Button floated="right" onClick={this.handleAddStaff} type="button">
                <Icon name='plus' />{t('button.addStaff')}
              </Form.Button>
            </Grid.Column>
            <Grid.Column width={11}>
              {
                this.state.input.staff.map(
                  (user, index) => (
                    <Grid key={`userSet${index}`}>
                      <Grid.Column width={14}>
                        <Form.Dropdown
                          type="text"
                          error={
                            this.state.message.field[`staff.${index}`]
                            && {
                              content: this.state.message.field[`staff.${index}`].join("\n"),
                              pointing: 'below',
                            }
                          }
                          fluid
                          label={t('input.label.staff')}
                          name="staff"
                          options={this.state.users}
                          onChange={this.handleInputArrayChange(index)}
                          placeholder={t('input.placeholder.staff')}
                          required
                          search
                          selection
                          value={user}
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Form.Button
                          color="red"
                          disabled={index === 0}
                          label={t('button.removeStaff')}
                          icon="cancel"
                          onClick={this.handleRemoveStaff(index)}
                          type="button"
                        />
                      </Grid.Column>
                    </Grid>
                  )
                )
              }
            </Grid.Column>
          </Grid>          
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={onClose} type="reset">
            <Icon name='remove' /> {t('button.cancel')}
          </Button>
          <Button color='blue' type="reset">
            <Icon name='refresh' /> {t('button.reset')}
          </Button>
          <Button color='green' type="submit">
            <Icon name='save' /> {t('button.save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

/**
 * @static {Object}
 */
Create.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withTranslation('clients')(Create);
