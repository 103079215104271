import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Form, Message } from 'semantic-ui-react';

import { Content } from '../../components';
import { AuthContext } from '../../contexts';
import { API, Logger } from '../../services';

const {
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_CLIENT_SECRET,
  REACT_APP_SSO_AUTH_URI,
} = process.env;

class Login extends Component {

  state = {
    input: {},
    loading: false,
    message: {
      field: {},
      info: 'login',
    },
  };
  passiveSupported = false;

  constructor(props) {
    super(props);
    this.handleCheckboxChange = this.checkboxChangeListener.bind(this);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.handleInputChange = this.inputChangeListener.bind(this);
    this.handleSSORequest = this.SSORequestListener.bind(this);
    this.handleSSOResponse = this.SSOResponseListener.bind(this);
    this.handleSubmit = this.submitListener.bind(this);
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {CheckboxProps}  data
   */
  checkboxChangeListener(event, { checked, name }) {
    const { input } = this.state;
    input[name] = checked ? 'On' : 'Off';
    this.setState({ input });
  }

  composnentDidMount() {
    try {
      const options = {
        get passive() {
          this.passiveSupported = true;
          return false;
        }
      };
      window.addEventListener("test", null, options);
      window.removeEventListener("test", null, options);
    } catch (err) {
      this.passiveSupported = false;
    }
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {InputOnChangeData}  data
   */
  inputChangeListener(event, { name, value }) {
    const { input } = this.state;
    input[name] = value;
    this.setState({ input });
  }

  /**
   * @param  {String} name
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({ message });
    };
  }

  /**
   * @param  {React.MouseEvent} event
   * 
   * @return {void}
   */
  SSORequestListener(event) {
    this.setState({ loading: true });
    window.addEventListener(
      'message',
      this.handleSSOResponse,
      this.passiveSupported ? { passive: true } : false
    );
    window.open(
      `${REACT_APP_SSO_AUTH_URI}?origin=${window.location.protocol}//${window.location.host}`,
      '_blank',
    );
  }

  /**
   * @param {MessageEvent} event
   *
   * @return void
   */
  SSOResponseListener(event) {
    if (event.origin === "https://auditxess.digitalrevisor.eu") {
      const data = event.data;
      event.source.close();
      this.setState({ loading: false });
      window.removeEventListener(
        'message',
        this.handleSSOResponse,
        this.passiveSupported ? { passive: true } : false
      )
      if (data.hasOwnProperty('user')) {
        const { token: credential, user } = data;
        this.context.authenticate({ credential, user });
        this.props.history.replace('/');
      } else if (data.hasOwnProperty('profile')) {
        this.context.signUp(data.profile);
        this.props.history.replace('/auth/register');
      } else {
        const { message } = this.state;
        message['error'] = 'sso';
        this.setState({ message });
      }
    }
  }

  /**
   * @param  {React.FormEvent} event
   */
  submitListener(event) {
    event.preventDefault();
    this.setState({ loading: true });
    API
      .post(
        '/api/v1/auth/login',
        {
          client_id: REACT_APP_AUTH_CLIENT_ID,
          client_secret: REACT_APP_AUTH_CLIENT_SECRET,
          ...this.state.input,
        },
      )
      .then((response) => {
        const { token: credential, user } = response.data;
        this.setState({ input: {} });
        this.context.authenticate({ credential, user });
        this.props.history.replace('/');
      })
      .catch((reason) => {
        Logger.error(reason);
        const { message } = this.state;
        message.error = 'login';
        message.field = reason.response.data.errors || {};
        this.setState({ message });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  /**
   * @return {React.Component}
   */
  render() {
    const { t } = this.props;
    return (
      <Content.Auth message={this.state.message.info}>
        <Form error={!!this.state.message.error} onSubmit={this.handleSubmit} loading={this.state.loading}>
          <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
          <Form.Input
            type="email"
            autoComplete="email"
            error={
              this.state.message.field.email
              && {
                content: this.state.message.field.email.join("\n"),
                pointing: 'below'
              }
            }
            label={t('input.label.email')}
            maxLength={128}
            minLength={3}
            name="email"
            onChange={this.handleInputChange}
            placeholder={t('input.placeholder.email')}
            required
          />
          <Form.Input
            type="password"
            autoComplete="current-password"
            error={
              this.state.message.field.password
              && {
                content: this.state.message.field.password.join("\n"),
                pointing: 'below',
              }
            }
            label={t('input.label.password')}
            maxLength={128}
            minLength={8}
            name="password"
            onChange={this.handleInputChange}
            placeholder={t('input.placeholder.password')}
            required
          />
          <Form.Checkbox
            error={
              this.state.message.field.remember
              && {
                content: this.state.message.field.remember.join("\n"),
                pointing: 'below',
              }
            }
            label={t('input.label.remember')}
            name="remember"
            onChange={this.handleCheckboxChange}
            toggle
          />
          <Form.Button color='blue' fluid type='submit'>{t('button.login')}</Form.Button>
          <p className="ax-auth-centered">
            {t('message.forgotPassword')} <Link to="/auth/password/link">{t('link.forgotPassword')}</Link>
            <br />
            {t('message.register')} <a href="https://gluu.digitalrevisor.eu/portal/">{t('link.register')}</a>
          </p>
          <Form.Button
            color='green'
            fluid
            onClick={this.handleSSORequest}
            type='button'
          >
            {t('button.singleSignOn')}
          </Form.Button>
        </Form>
      </Content.Auth>
    );
  }
}

/**
 * @static {React.Context<Auth>}
 */
Login.contextType = AuthContext;

export default withTranslation('auth')(Login);
