import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Message, Tab } from 'semantic-ui-react';

import { Content } from '../../components';
import Company from './Company';
import Profile from './Profile';
import Security from './Security';
import System from './System';

class Settings extends Component {

  /**
   * @property {Object}
   */
  state = {
    message: {
      info: 'default',
    },
  }

  constructor(props) {
    super(props);
    this.handleDismissInfoMessage = this.messageDismissedListener('info').bind(this);
  }

  /**
   * @param  {String} name
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({ message });
    };
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    const { t } = this.props;
    return (
      <Content title={t('title')}>
        {
          this.state.message.info
          && <Message
            content={t(`message.info.${this.state.message.info}.content`)}
            icon='info'
            info
            header={t(`message.info.${this.state.message.info}.title`)}
            onDismiss={this.handleDismissInfoMessage}
          />
        }
        <Tab panes={[
          {
            menuItem: t('pane.profile'),
            render: () => <Profile />,
          },
          {
            menuItem: t('pane.company'),
            render: () => <Company />,
          },
          {
            menuItem: t('pane.system'),
            render: () => <System />,
          },
          {
            menuItem: t('pane.security'),
            render: () => <Security />,
          },
        ]} />
      </Content>
    );
  }

}

export default withTranslation('settings')(Settings);
