import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import {
  Button,
  Dimmer,
  Form,
  Grid,
  Header,
  Icon,
  Loader,
  Message,
  Modal,
} from 'semantic-ui-react';

import { API, FormErrorHandler } from '../../services';

class Create extends Component {
  /**
   * @property {Objact}
   */
  state = {
    input: {
      permissions: [null],
    },
    loading: false,
    message: {
      field: {},
    },
    permissions: [],
  };

  /**
   * @param  {Readonly<Object>}  props
   * @constructs Create
   */
  constructor(props) {
    super(props);
    this.handleAddPermission = this.addPermissionListener.bind(this);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.handleDismissSuccessMessage = this.messageDismissedListener('success').bind(this);
    this.handleInputArrayChange = index => _.bindKey(this, 'handleInputChange', _, _, index);
    this.handleInputChange = this.inputChangeListener.bind(this);
    this.handleMount = this.mountListener.bind(this);
    this.handleRemovePermission = this.removePermissionListener.bind(this);
    this.handleReset = this.resetListener.bind(this);
    this.handleSubmit = this.submitListener.bind(this);
    this.handleUnmount = this.unmountListener.bind(this);
  }

  /**
   * @param {React.MouseEvent} event
   */
  addPermissionListener(event) {
    this.setState({
      input: {
        ...this.state.input,
        permissions: [
          null,
          ...this.state.input.permissions,
        ],
      }
    });
  }

  /**
   * @param  {React.ChangeEvent}  event
   * @param  {InputOnChangeData}  data
   * @param  {String}  data.name
   * @param  {number}  index
   * @param  {any}  data.value
   */
  inputChangeListener(event, { name, value }, index) {
    const { input } = this.state;
    if (index !== undefined) {
      input[name][index] = value;
    } else {
      input[name] = value;
    }
    this.setState({ input });
  }

  /**
   * @param  {String} name
   *
   * @return {Function(event: React.MouseEvent): void}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({
        message: {
          ...this.state.message,
          [name]: null,
        },
      });
    };
  }

  /**
   * @param  {null}  nothing
   * @param  {ModalProps}  data
   * 
   * @return {void}
   */
  mountListener(nothing, data) {
    if (!this.state.loading) {
      this.setState({
        loading: true,
      });
      API
        .get('/api/v1/permissions')
        .then((response) => {
          this.setState({
            permissions: response.data.map(
              (permission, index) => ({
                key: index,
                text: permission.name,
                value: permission.id,
              })
            ),
          });
        })
        .catch((reason) => {
          this.setState({
            message: {
              ...this.state.message,
              error: 'retrieve',
            },
          });
        })
        .finally(() => {
          this.setState({
            loading: false,
          });
        });
    }
  }

  /**
   * @param  {Object}  errors
   * 
   * @return {void}
   */
  onFieldError(errors) {
    this.setState({
      message: {
        error: 'fields',
        success: null,
        field: errors,
      },
    });
  }

  /**
   * @return {void}
   */
  onServerError() {
    this.setState({
      message: {
        error: 'server',
        success: null,
        field: {},
      },
    });
  }

  /**
   * @return {void}
   */
  onUnknownError() {
    this.setState({
      message: {
        error: 'unexpected',
        success: null,
        field: {},
      },
    });
  }

  /**
   * @param  {number}  index
   * 
   * @return {Function(event: React.MouseEvent): void}
   */
  removePermissionListener(index) {
    return (event) => {
      const { input } = this.state;
      input.permissions.splice(index, 1);
      this.setState({ input });
    };
  }

  /**
   * @param  {React.FormEvent}  event
   * 
   * @return {void}
   */
  resetListener(event) {
    event.preventDefault();
    this.setState({
      input: {
        permissions: [null],
      },
    });
  }

  /**
   * @param  {React.FormEvent}  event
   * 
   * @return {void}
   */
  submitListener(event) {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    API
      .post('/api/v1/roles', this.state.input)
      .then((reponse) => {
        this.setState({
          input: {
            name: null,
            permissions: [null],
          },
          message: {
            error: null,
            field: {},
            success: 'create',
          },
        });
      })
      .catch((reason) => {
        FormErrorHandler(
          reason,
          {
            onFieldError: this.onFieldError.bind(this),
            onServerError: this.onServerError.bind(this),
            onUnknownError: this.onUnknownError.bind(this),
          }
        );
      })
      .finally(() => {
        this.setState({
          loading: false,
        });
      });
  }

  /**
   * @param  {null}  nothing
   * @param  {SemanticUI.ModalProps}  data
   * 
   * @return {void}
   */
  unmountListener(nothing, data) {
    this.setState({
      input: {
        permissions: [null],
      },
      message: {
        field: {},
      },
      permissions: [],
    });
  }

  /**
   * @return {React.ReactNode}
   */
  render() {
    const { onClose, open, t } = this.props;
    return (
      <Modal
        as={Form}
        closeIcon={!this.state.loading}
        closeOnDimmerClick={!this.state.loading}
        closeOnDocumentClick={!this.state.loading}
        error={Boolean(this.state.message.error)}
        onClose={onClose}
        onMount={this.handleMount}
        onReset={this.handleReset}
        onSubmit={this.handleSubmit}
        onUnmount={this.handleUnmount}
        open={open}
        success={Boolean(this.state.message.success)}
      >
        <Dimmer active={this.state.loading} inverted>
          <Loader />
        </Dimmer>
        <Header icon='add' content={t('title.create')} />
        <Modal.Content scrolling>
          <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
          <Message
            content={t(`message.success.${this.state.message.success}.content`)}
            success
            icon='check'
            header={t(`message.success.${this.state.message.success}.title`)}
            onDismiss={this.handleDismissSuccessMessage}
          />
          <Grid>
            <Grid.Column width={5}>
              <Form.Input
                type="text"
                error={
                  this.state.message.field.name
                  && {
                    content: this.state.message.field.name.join("\n"),
                    pointing: 'below',
                  }
                }
                label={t('input.label.name')}
                name="name"
                onChange={this.handleInputChange}
                placeholder={t('input.placeholder.name')}
                required
                value={this.state.input.name}
              />
              <Form.Button floated="right" onClick={this.handleAddPermission} type="button">
                <Icon name='plus' />{t('button.addPermission')}
              </Form.Button>
            </Grid.Column>
            <Grid.Column width={11}>
              {
                this.state.input.permissions.map(
                  (permission, index) => (
                    <Grid key={`permissionSet${index}`}>
                      <Grid.Column width={14}>
                        <Form.Dropdown
                          type="text"
                          error={
                            this.state.message.field[`permissions.${index}`]
                            && {
                              content: this.state.message.field[`permissions.${index}`].join("\n"),
                              pointing: 'below',
                            }
                          }
                          fluid
                          label={t('input.label.permission')}
                          name="permissions"
                          options={this.state.permissions}
                          onChange={this.handleInputArrayChange(index)}
                          placeholder={t('input.placeholder.permission')}
                          required
                          search
                          selection
                          value={permission}
                        />
                      </Grid.Column>
                      <Grid.Column width={2}>
                        <Form.Button
                          color="red"
                          disabled={index === 0}
                          label={t('button.removePermission')}
                          icon="cancel"
                          onClick={this.handleRemovePermission(index)}
                          type="button"
                        />
                      </Grid.Column>
                    </Grid>
                  )
                )
              }
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={onClose} type="reset">
            <Icon name='remove' /> {t('button.cancel')}
          </Button>
          <Button color='blue' type="reset">
            <Icon name='refresh' /> {t('button.reset')}
          </Button>
          <Button color='green' type="submit">
            <Icon name='save' /> {t('button.save')}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

/**
 * @static {Object}
 */
Create.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withTranslation('roles')(Create);
