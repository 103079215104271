import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ProjectList from './List';
import Project from './Project';

class Projects extends Component {
  /**
   * @return {React.ReactNode}
   */
  render() {
    const { match: { url } } = this.props;
    return (
      <Switch>
        <Route component={ProjectList} exact path={url} />
        <Route component={Project} path={`${url}/:projectId`} />
      </Switch>
    );
  }
}

export default Projects;