import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Card, Grid, Image, Message } from 'semantic-ui-react';

import Create from './Create';
import { Content } from '../../components';
import { API } from '../../services';

class Clients extends Component {
  /**
   * @property {Object}
   */
  state = {
    creating: false,
    clients: [],
    loading: true,
    message: {},
  };

  /**
   * @param {Readonly} props
   * 
   * @constructs List
   */
  constructor(props) {
    super(props);
    this.handleCreateClose = this.createCloseListener.bind(this);
    this.handleDismissErrorMessage = this.messageDismissedListener('error').bind(this);
    this.pageActions = [
      {
        handler: this.createOpenListener.bind(this),
        label: props.t('button.create'),
      },
    ];
  }

  /**
   * @return {void}
   */
  componentDidMount() {
    API
      .get('/api/v1/clients')
      .then((response) => {
        this.setState({
          clients: response.data,
        });
      })
      .catch((reason) => {
        this.setState({
          message: {
            ...this.state.message,
            error: 'retrieve',
          },
        });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  /**
   * @param  {React.MouseEvent} event 
   * 
   * @return {void}
   */
  createCloseListener(event) {
    this.setState({ creating: false });
  }

  /**
   * @param  {React.MouseEvent} event 
   * 
   * @return {void}
   */
  createOpenListener(event) {
    this.setState({ creating: true });
  }

  /**
   * @param  {String} name
   *
   * @return {function(React.MouseEvent)}
   */
  messageDismissedListener(name) {
    return (event) => {
      const { message } = this.state;
      message[name] = null;
      this.setState({ message });
    };
  }

  render() {
    const { match: { url }, t } = this.props;
    return (
      <Content actions={this.pageActions} title={t('title.index')}>
        {
          this.state.message.error
          && <Message
            content={t(`message.error.${this.state.message.error}.content`)}
            error
            icon='exclamation'
            header={t(`message.error.${this.state.message.error}.title`)}
            onDismiss={this.handleDismissErrorMessage}
          />
        }
        {
          this.state.clients.length === 0
          && (
            <Grid centered columns={3}>
              <Grid.Column>
                <Message
                  content={t(`message.warn.empty.content`)}
                  icon='exclamation'
                  header={t(`message.warn.empty.title`)}
                  warning
                />
              </Grid.Column>
            </Grid>
          )
        }
        <Grid columns={2}>
          {this.state.clients.map(
            (client, index) => (
              <Grid.Column>
                <Card>
                  <Card.Content>
                    <Image avatar src='/favicon.ico' />
                    <Card.Header>{client.name}</Card.Header>
                    <Card.Meta>{(new Date(client.created_at)).toLocaleString()}</Card.Meta>
                  </Card.Content>
                  <Card.Content extra>
                    <Button as={Link} to={`${url}/${client.id}`}>{}</Button>
                  </Card.Content>
                </Card>
              </Grid.Column>
            )
          )}
        </Grid>
        <Create onClose={this.handleCreateClose} open={this.state.creating} />
      </Content>
    );
  }
}

export default withTranslation('clients')(Clients);